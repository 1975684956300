import { Box, Typography } from '@mui/material';

import FormControls from '../MUI/FormControls/FormControls';
// import classes from './OrderForm.module.css';

const DeliveryLocationAutocompleteFormField = (props) => {
  // Testing.
  // console.count('** DeliveryLocationAutocompleteFormField - rendered - ');

  const {
    deliveryLocations,
    currentDeliveryLocation,
    onChange,
    label = 'Delivery Location',
    type = '',
    onResetDeliveryType,
    labelClearDeliveryType = '',
    onClearDeliveryType,
  } = props;

  const displaySetDeliveryLocationLink = false;

  // console.log('DeliveryLocationAutocompleteFormField - currentDeliveryLocation');
  // console.log(currentDeliveryLocation);
  // console.log('DeliveryLocationAutocompleteFormField - deliveryLocations');
  // console.log(deliveryLocations);

  // console.log('deliveryLocations');
  // console.log(deliveryLocations);

  // let deliveryLocations = [];
  // if (!propsDeliveryLocations) {
  //   deliveryLocations = propsDeliveryLocations;
  // }

  return deliveryLocations ? (
    <Box component='div'>
      {/* <Typography variant='h7' component='label' color='primary' className='required-label'> */}
      <Typography variant='formLabel' component='label'>
        {label}
        {labelClearDeliveryType ? (
          <button type='button' onClick={onClearDeliveryType} className='button-link'>
            ({labelClearDeliveryType})
          </button>
        ) : null}

        {displaySetDeliveryLocationLink ? (
          <>
            {type === 'Airport' ? (
              <button
                type='button'
                onClick={() => onResetDeliveryType('Stadium')}
                className='button-link'
              >
                (Stadium)
              </button>
            ) : null}
            {type === 'Stadium' ? (
              <button
                type='button'
                onClick={() => onResetDeliveryType('Airport')}
                className='button-link'
              >
                (Airport)
              </button>
            ) : null}
          </>
        ) : null}
      </Typography>

      <FormControls.AutocompleteControl
        id='deliveryLocation'
        // label='Delivery Location'
        options={deliveryLocations}
        getOptionLabel={(option) => option.name + ' (' + option.city + ', ' + option.state + ')'}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        // inputRef={deliveryLocationRef}
        // inputRef={props.deliveryLocationRef}
        // className={classes['mui-control']}
        // fullWidth={true}
        // value={props.deliveryLocations[0]}
        // value={currentDeliveryLocationName}
        defaultValue={currentDeliveryLocation}
        placeholder='Select A Delivery Location'
        onChange={onChange}
        // InputLabelProps={{ shrink: true }}
        sx={{ minWidth: '50rem' }}
      />
    </Box>
  ) : null;
};

export default DeliveryLocationAutocompleteFormField;
