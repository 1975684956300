import { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  // Tooltip,
  Typography,
} from '@mui/material';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReviewsIcon from '@mui/icons-material/Reviews';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RecommendIcon from '@mui/icons-material/Recommend';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ConstructionIcon from '@mui/icons-material/Construction';
import ViewListIcon from '@mui/icons-material/ViewList';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { environmentTitle } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import Popup from '../MUI/UI/Popup';
import HeaderCartButton from './HeaderCartButton';
import Cart from '../MUI/Cart/Cart';

// import EliteEatsLogo from './elite-eats-logo-image.png';
import * as Constants from '../../helpers/Constants';
import classes from '../CSS/Header.module.css';

let popupTitle = 'Your Cart';

const Header = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const isLoggedIn = authCtx.isLoggedIn;
  const userName = authCtx.userName;
  const userRole = authCtx.userRole;
  const orgName =
    userRole === 'Vendor' && authCtx.selectedRestaurantName
      ? authCtx.selectedRestaurantName
      : authCtx.orgName;
  const orgDetails = authCtx.orgDetails;
  const orgStatus = orgDetails && orgDetails.orgStatus ? orgDetails.orgStatus : '';

  const multipleRestaurants = authCtx.multipleRestaurants;

  const orderNumber = orderCtx.orderDetails.orderNumber;
  const dashboardUrl = authCtx.dashboardUrl;
  const orderMode = orderCtx.orderMode;

  const [openPopup, setOpenPopup] = useState(false);
  // const [userMenuAchorElement, setUserMenuAnchorElement] = useState(null);
  // const openUserMenu = Boolean(userMenuAchorElement);

  // const handleOpenUserMenu = (event) => {
  //   setUserMenuAnchorElement(event.currentTarget);
  // };

  // const handleCloseUserMenu = () => {
  //   setUserMenuAnchorElement(null);
  // };

  const [accountMenuAchorElement, setAccountMenuAnchorElement] = useState(null);
  const openAccountMenu = Boolean(accountMenuAchorElement);

  const history = useHistory();

  const handleOpenAccountMenu = (event) => {
    setAccountMenuAnchorElement(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAccountMenuAnchorElement(null);
  };

  const userMenuItemSelected = (display) => {
    history.replace(dashboardUrl + display);
  };

  let displayName = userName;
  if (orgName !== '' && orgName !== undefined && orgName !== 'undefined') {
    displayName += ' (' + orgName + ')';
  }

  const adminMenuPath = '/admin-dashboard/';
  const teamMenuPath = '/team-dashboard/';
  const vendorMenuPath = '/vendor-dashboard/';

  const logoutHandler = () => {
    // setUserMenuAnchorElement(null);
    // I should clean up all Context Data when logging out.
    orderCtx.clearOrderItems();
    orderCtx.resetOrder();
    authCtx.logout();
  };

  const showCartHandler = () => {
    popupTitle = orderNumber ? 'Order: ' + orderNumber : 'Your Cart';
    setOpenPopup(true);
  };

  const reloadApp = () => {
    console.log('### reloadApp ###');
    window.location.reload();
  };

  const iconMode = 'internal';
  const iconGoesTo = userRole === 'User' ? '/team-dashboard/new-order' : dashboardUrl + 'orders';

  return (
    <Box className={classes['header-container']}>
      <Box className={classes['header-content-container']}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0px', paddingRight: '40px' }}>
            <Box>
              {iconMode === 'internal' ? (
                <Link to={iconGoesTo}>
                  <div className={classes['logo-container']}>
                    <img
                      src={
                        // Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_logo_white_text_color_logo.png'
                        Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_e_100.png'
                      }
                      alt='Elite Eats Logo'
                      className={classes['logo-img']}
                    />
                  </div>
                </Link>
              ) : null}

              {iconMode === 'external' ? (
                <a
                  href='https://elite-eats.com'
                  target='_blank'
                  rel='noreferrer'
                  className={classes.logo}
                >
                  <div className={classes['logo-container']}>
                    <img
                      src={
                        // Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_logo_white_text_color_logo.png'
                        Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_e_100.png'
                      }
                      alt='Elite Eats Logo'
                      className={classes['logo-img']}
                    />
                  </div>
                </a>
              ) : null}
            </Box>

            {environmentTitle ? (
              <Box sx={{ color: 'white', paddingTop: '16px' }}>
                <Typography variant='h2' component='h2'>
                  {environmentTitle}
                </Typography>
              </Box>
            ) : null}

            {authCtx.versionUpdatedRefreshNeeded ? (
              <Box align='center' sx={{ color: 'white', paddingTop: '2px', paddingLeft: '50px' }}>
                A new version of the Elite Eats App is available.
                <Box sx={{ paddingTop: '2px' }}>
                  <button className='small' onClick={reloadApp}>
                    Update App
                  </button>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box>
            <div className={classes['top-nav']}>
              {isLoggedIn ? (
                <Stack direction='row' sx={{ height: '4rem' }}>
                  {orderMode !== 'checkout' && userRole === 'User' && orgStatus !== 'New' ? (
                    <HeaderCartButton onShowCart={showCartHandler} />
                  ) : null}

                  {/* <Tooltip title='MENU'> */}
                  <IconButton
                    id='account-menu-button'
                    className={classes['account-menu-button']}
                    // onClick={handleOpenAccountMenu}
                    onMouseOver={handleOpenAccountMenu}
                    // size='small'
                    sx={{ ml: 2 }}
                    aria-controls={openAccountMenu ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={openAccountMenu ? 'true' : undefined}
                  >
                    <Typography component='div' sx={{ pt: '2px', pl: '10px', pr: '10px' }}>
                      {displayName}
                    </Typography>
                    {/* <Avatar sx={{ width: 40, height: 40 }}>JC</Avatar> */}
                    <Box sx={{ pt: '10px' }}>
                      <AccountBoxIcon color='var(--clr-neutral-100)' sx={{ fontSize: '3rem' }} />
                    </Box>
                  </IconButton>
                  {/* </Tooltip> */}
                </Stack>
              ) : null}

              {!isLoggedIn ? (
                <nav>
                  <ul>
                    <li>
                      <Link to='/login'>Login</Link>
                    </li>
                    <li>
                      <Link to='/team-main'>Team Signup</Link>
                    </li>
                    <li>
                      <Link to='/vendor-main'>Vendor Signup</Link>
                    </li>
                  </ul>
                </nav>
              ) : null}
            </div>
          </Box>
        </Box>
      </Box>

      {/* <Menu
        id='user-menu'
        anchorEl={userMenuAchorElement}
        open={openUserMenu}
        onClick={handleCloseUserMenu}
        onClose={handleCloseUserMenu}
        MenuListProps={{ 'aria-labelledby': 'user-menu-button', onMouseLeave: handleCloseUserMenu }}
        disableScrollLock={true}
        disableAutoFocusItem={true}
        transitionDuration={500}
      >
        <MenuItem
          className={classes['menu-item']}
          onClick={() => {
            userMenuItemSelected('account');
          }}
        >
          <AccountBoxIcon />
          <Typography variant='h7' component='div' className={classes['menu-item-label']}>
            My Account
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes['menu-item']}
          onClick={() => {
            userMenuItemSelected('dashboard');
          }}
        >
          <DashboardIcon />
          <Typography variant='h7' component='div' className={classes['menu-item-label']}>
            Dashboard
          </Typography>
        </MenuItem>
        <MenuItem className={classes['menu-item']} onClick={logoutHandler}>
          <LogoutIcon />
          <Typography variant='h7' component='div' className={classes['menu-item-label']}>
            Logout
          </Typography>
        </MenuItem>
      </Menu> */}

      <Menu
        anchorEl={accountMenuAchorElement}
        id='account-menu'
        open={openAccountMenu}
        onClose={handleCloseAccountMenu}
        onClick={handleCloseAccountMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          // 'aria-labelledby': 'account-menu-button',
          // onMouseLeave: handleCloseAccountMenu,
          onClick: handleCloseAccountMenu,
        }}
        disableScrollLock={false}
        disableAutoFocusItem={true}
        transitionDuration={500}
      >
        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'new-order');
            }}
          >
            <FiberNewIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Start New Order
            </Typography>
          </MenuItem>
        ) : null}
        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'orders');
            }}
          >
            <ListAltIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Orders
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'expense-report');
            }}
          >
            <ReceiptIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Expense Reports
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'restaurants');
            }}
          >
            <RestaurantIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Restaurants
            </Typography>
          </MenuItem>
        ) : null}

        {/* {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'delivery-locations');
            }}
          >
            <LocationOnIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Delivery Locations
            </Typography>
          </MenuItem>
        ) : null} */}

        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'refer-vendor');
            }}
          >
            <RecommendIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Refer A Fueling Partner
            </Typography>
          </MenuItem>
        ) : null}

        {/* {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(teamMenuPath + 'restaurants');
            }}
          >
            <RestaurantIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Restaurants
            </Typography>
          </MenuItem>
        ) : null} */}

        {userRole === 'Vendor' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(vendorMenuPath + 'orders');
            }}
          >
            <ListAltIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Orders
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Vendor' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(vendorMenuPath + 'reviews');
            }}
          >
            <ReviewsIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Reviews
            </Typography>
          </MenuItem>
        ) : null}
        {userRole === 'Vendor' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(vendorMenuPath + 'menu-items');
            }}
          >
            <MenuBookIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Menu
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Vendor' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(vendorMenuPath + 'delivery-locations');
            }}
          >
            <LocationOnIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Delivery Locations
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Vendor' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              userMenuItemSelected('vendor-profile');
            }}
          >
            <AccountBoxIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Profile
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Vendor' && orgStatus === 'Active' && multipleRestaurants ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(vendorMenuPath + 'restaurants');
            }}
          >
            <RestaurantIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Restaurants
            </Typography>
          </MenuItem>
        ) : null}

        {/* Start Admin Menu */}

        {(orgStatus === 'Active') & (userRole === 'Admin') ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              userMenuItemSelected('dashboard');
            }}
          >
            <DashboardIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Dashboard
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'orders');
            }}
          >
            <ViewListIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Orders
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'users');
            }}
          >
            <PeopleAltIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Users
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'teams');
            }}
          >
            <GroupsIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Teams
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'restaurants');
            }}
          >
            <RestaurantIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Restaurants
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'vendor-referrals');
            }}
          >
            <RecommendIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Referrals
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'delivery-locations');
            }}
          >
            <LocationOnIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Delivery Locations
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? <Divider /> : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'leagues');
            }}
          >
            <Diversity3Icon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Leagues
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'food-types');
            }}
          >
            <LunchDiningIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Food Types
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'menu-item-categories');
            }}
          >
            <MenuBookIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Menu Categories
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'Admin' ? <Divider /> : null}

        {/* End Admin Menu */}

        {/* {orgStatus === 'Active' ? <Divider /> : null} */}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              userMenuItemSelected('account');
            }}
          >
            <AccountBoxIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              My Account
            </Typography>
          </MenuItem>
        ) : null}

        {userRole === 'User' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              userMenuItemSelected('team-profile');
            }}
          >
            <GroupsIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Team Profile
            </Typography>
          </MenuItem>
        ) : null}

        {/* {(userRole === 'User' || userRole === 'Vendor') && orgStatus === 'Active' ? ( */}
        {userRole === 'DoNoUse' && orgStatus === 'Active' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              userMenuItemSelected('user-profile');
            }}
          >
            <AccountBoxIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              User Profile
            </Typography>
          </MenuItem>
        ) : null}

        {(userRole === 'User' || userRole === 'Vendor') && orgStatus === 'Active' ? (
          <Divider />
        ) : null}

        <MenuItem className={classes['menu-item']} onClick={logoutHandler}>
          <LogoutIcon />
          <Typography variant='h7' component='div' className={classes['menu-item-label']}>
            Logout
          </Typography>
        </MenuItem>

        {userRole === 'Admin' ? <Divider /> : null}
        {userRole === 'Admin' ? <Divider /> : null}

        {userRole === 'Admin' ? (
          <MenuItem
            className={classes['menu-item']}
            onClick={() => {
              history.replace(adminMenuPath + 'testing');
            }}
          >
            <ConstructionIcon />
            <Typography variant='h7' component='div' className={classes['menu-item-label']}>
              Test Stuff
            </Typography>
          </MenuItem>
        ) : null}
      </Menu>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth='sm' title={popupTitle}>
        <Cart setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default Header;
