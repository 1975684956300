import React from 'react';
import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';

import { servingSizeLabel, priceDetailsLabel } from '../../constants/global';

import MenuItemMainDetails from './MenuItemMainDetails';

// import classes from './MenuItem.module.css';
import classes from '../CSS/MenuItem.module.css';
import classesTable from '../CSS/styled-table.module.css';

const MenuItemWithOptionsCard = (props) => {
  const { menuItem, userRole, onDisplayDetails, onDisplayForm } = props;

  // menuItem.imageUrl = '';
  const displayDetailsButton = true;
  const displayEditButton = true;

  const displayTableHeader = () => {
    return (
      <tr>
        <th>{servingSizeLabel}</th>
        <th>Feeds</th>
        <th className='text-right'>Price</th>
        <th>{priceDetailsLabel}</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return (
      <tr>
        <td>{menuItem.servingSize}</td>
        <td>{menuItem.feeds}</td>
        <td className='text-right'>{menuItem.priceFormatted ? menuItem.priceFormatted : menuItem.price}</td>
        <td>{menuItem.priceDetails}</td>
      </tr>
    );
  };

  const displayTableBodyChildren = () => {
    return menuItem.options.map((menuItem, index) => (
      <tr key={index}>
        <td>{menuItem.servingSize}</td>
        <td>{menuItem.feeds}</td>
        <td className='text-right'>{menuItem.priceFormatted ? menuItem.priceFormatted : menuItem.price}</td>
        <td>{menuItem.priceDetails}</td>
      </tr>
    ));
  };

  return (
    <Paper elevation={4} sx={{ p: '20px', mb: '20px' }}>
      <Box className='flex-column-space-between'>
        <Box sx={{ width: '100%' }}>
          <Box className={classes['menu-item-header-image-holder']}>
            {menuItem.imageUrl ? (
              <Tooltip title='Click to display fullsize image.'>
                <a href={menuItem.imageUrl} target='_blank' rel='noreferrer'>
                  <img src={menuItem.imageUrl} alt='Menu Item' />
                </a>
              </Tooltip>
            ) : null}

            {!menuItem.imageUrl ? (
              <Box className={classes['menu-item-image-filler']}>
                <Typography variant='h2' component='h2'>
                  No Image
                </Typography>
                <Typography>(600px x 140px)</Typography>
              </Box>
            ) : null}
          </Box>

          <MenuItemMainDetails
            menuItem={menuItem}
            userRole={userRole}
            onDisplayDetails={onDisplayDetails}
            // onDisplayForm={onDisplayForm}
          />

          {menuItem.price > 0 ? (
            <Box sx={{ pb: '20px' }}>
              <table className={`${classesTable['styled-table']} ${classesTable['full-width']}`}>
                <thead>{displayTableHeader()}</thead>
                <tbody>
                  {displayTableBody()}
                  {displayTableBodyChildren()}
                </tbody>
              </table>
            </Box>
          ) : null}

          {displayDetailsButton || displayEditButton ? (
            <Box className='button-holder'>
              {displayDetailsButton && userRole === 'Admin' ? (
                <Button
                  variant='contained'
                  size='custom'
                  color='primary'
                  onClick={() => onDisplayDetails(menuItem.menuItemUuid)}
                >
                  Details
                </Button>
              ) : null}
              {displayEditButton && userRole === 'Admin' ? (
                <Button
                  variant='contained'
                  size='custom'
                  color='primary'
                  onClick={() => onDisplayForm(menuItem.menuItemUuid)}
                >
                  Edit Menu Item
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Paper>
  );
};

export default MenuItemWithOptionsCard;
