import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const DeliveryLocationCard = ({ details, userRole }) => {
  const displayDeliveryType = false;

  return (
    <Paper elevation={4} sx={{ p: '20px', mb: '20px' }}>
      <Box className='flex-container-side-by-side'>
        <Box>
          <Typography variant='h2' component='h2' sx={{ pb: '10px' }}>
            {details.name}
          </Typography>
          <Box sx={{ pb: '10px' }}>
            <Typography component='div'>
              {details.address1}
              {details.address2 ? ', ' + details.address2 : ''}
              {details.city ? ', ' + details.city : ''}
              {details.state ? ', ' + details.state : ''}
              {details.zipcode ? ', ' + details.zipcode : ''}
            </Typography>
          </Box>

          {userRole !== 'User' ? (
            <Box>
              <Typography component='div'>
                {details.phone ? details.phone : ''}
                {details.email ? (details.phone ? ' - ' : '') + details.email : ''}
              </Typography>
            </Box>
          ) : null}

          {details.leagues && userRole !== 'User' ? (
            <Box sx={{ pt: '10px' }}>
              <Typography variant='labelInline' component='span'>
                Leagues:
              </Typography>
              <Typography component='span'>{details.leagues}</Typography>
            </Box>
          ) : null}

          {userRole === 'Admin' || displayDeliveryType ? (
            <Box sx={{ pt: '10px' }} className='flex-container-side-by-side'>
              {displayDeliveryType ? (
                <Box>
                  <Typography variant='labelInline' component='span'>
                    Delivery Type:
                  </Typography>
                  <Typography component='span'>{details.type}</Typography>
                </Box>
              ) : null}

              {userRole === 'Admin' ? (
                <Box>
                  <Typography variant='labelInline' component='span'>
                    Status:
                  </Typography>
                  <Typography component='span'>{details.status}</Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>

        {details.deliveryInstructions ? (
          <Box>
            <Typography variant='label' component='div' sx={{ pb: '5px' }}>
              Delivery Instructions
            </Typography>
            {details.deliveryInstructions ? (
              <Typography component='pre'>{details.deliveryInstructions}</Typography>
            ) : (
              <Typography>Not entered...</Typography>
            )}
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
};

export default DeliveryLocationCard;
