import React from 'react';
import { Box } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import PdfOrderDetails from './PdfOrderDetails';

const PrintOrderDetails = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
    orderItems,
    additionalCharges,
    selectedRestaurant,
    userRole,
  } = props;

  return (
    <Box>
      <PDFViewer className='pdf-viewer'>
        <PdfOrderDetails
          deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
          deliveryLocationForDisplay={deliveryLocationForDisplay}
          recordDetails={recordDetails}
          orderContacts={orderContacts}
          orderItems={orderItems}
          additionalCharges={additionalCharges}
          selectedRestaurant={selectedRestaurant}
          userRole={userRole}
        />
      </PDFViewer>
    </Box>
  );
};

export default PrintOrderDetails;
