import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Collapse, Divider, Paper, Typography } from '@mui/material';

import {
  displayTeamCharge,
  teamChargeRate,
  teamChargeLabel,
  deliveryFeeLabel,
  eliteEatsPayoutMainLabel,
  eliteEatsPayoutSubLabel,
  paymentNotConfirmedStatusValue,
  orderLeadTimeExceededStatusValue,
} from '../../constants/global';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import * as Constants from '../../helpers/Constants';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import { useFormatData } from '../Hooks/useFormatData';
import WorkflowMessage from '../UI/WorkflowMessage';
import Popup from '../MUI/UI/Popup';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
// import OrderDetailsMessage from './OrderDetailsMessage';
import OrderMessages from '../OrderMessages/OrderMessages';
import OrderStatusNotesForm from './OrderStatusNotesForm';
import RestaurantReview from '../RestaurantReviews/RestaurantReview';
import PaymentForm from './PaymentForm';
import DeliveryDetails from './DeliveryDetails';
import PrintOrderDetails from '../ReactPdf/PrintOrderDetails';
import PrintOrderDetailsSubtotal from '../ReactPdf/PrintOrderDetailsSubtotal';
import PrintOrderReceipt from '../ReactPdf/PrintOrderReceipt';
import PrintOrderForKitchen from '../ReactPdf/PrintOrderForKitchen';
import OrderDetailsOrderedItems from './OrderDetailsOrderedItems';
import OrderDetailsTotalArea from './OrderDetailsTotalArea';
import FileAttachmentList from '../FileAttachments/FileAttachmentList';

import classes from './../CSS/Orders.module.css';

const displayRestaurantContacts = false;
const displayEditOrderButton = false;
const displayPrintOrderReceiptButton = false;
const displayPrintOrderDetailsSubtotalsOnlyButton = false;
const displayPrintOrderForKitchenButton = false;
const displayCaculateTotalsButton = false;

let displayModifyOrderButton = false;
let displayReorderButton = false;

const OrderDetails = (props) => {
  // console.log('######## OrderDetails ########');
  const { orderUuid } = props;

  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;

  const [recordDetails, setRecordDetails] = useState([]);
  const [orderContacts, setOrderContacts] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});
  const [selectedRestaurantUuid, setSelectedRestaurantUuid] = useState('');
  const [restaurantContacts, setRestaurantContacts] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState({});
  const [deliveryLocationForDisplay, setDeliveryLocationForDisplay] = useState({});
  // const [transactionData, setTransactionData] = useState(null);
  // const [refreshData, setRefreshData] = useState(false);
  const timestamp = new Date().getTime();
  const [refreshData, setRefreshData] = useState(timestamp);

  const [openRejectOrderForm, setOpenRejectOrderForm] = useState(false);
  const [openPopupDeliveryDetails, setOpenPopupDeliveryDetails] = useState(false);
  const [openPopupPaymentForm, setOpenPopupPaymentForm] = useState(false);
  const [openPopupPrintOrderDetails, setOpenPopupPrintOrderDetails] = useState(false);
  const [openPopupPrintOrderDetailsSubtotal, setOpenPopupPrintOrderDetailsSubtotal] =
    useState(false);
  const [openPopupPrintOrderReceipt, setOpenPopupPrintOrderReceipt] = useState(false);
  const [openPopupPrintOrderForKitchen, setOpenPopupPrintOrderForKitchen] = useState(false);

  const [showTeamContacts, setShowTeamContacts] = useState(false);
  const [showTeamContactsLabel, setShowTeamContactsLabel] = useState('Show Team Contacts');
  const [showRestaurantContacts, setShowRestaurantContacts] = useState(false);
  const [showRestaurantContactsLabel, setShowRestaurantContactsLabel] = useState(
    'Show Restaurant Contacts'
  );
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    type: 'General',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [showTeamCharge, setShowTeamCharge] = useState(displayTeamCharge);
  const [showTaxInfo, setShowTaxInfo] = useState(authCtx.displayTaxInfo);
  const [showTotal, setShowTotal] = useState(authCtx.displayTaxInfo);

  const history = useHistory();

  const {
    FormatDateForDisplay,
    FormatTimeForDisplay,
    FormatLongDateForDisplay,
    FormatLongDateAndTimeForDisplay,
  } = useFormatDateAndTime();

  const { FormatCurrencyForDisplay } = useFormatData();

  // Tempororay variables.
  const displayOrderTotalsTable = false;

  // Commenting this out for now.
  // TODO:  Cleanup this code at some point.
  // useEffect(() => {
  //   // console.log(' -- useEffect - OrderDetails - authCtx.resetLogoutTimer -- ');
  //   authCtx.resetLogoutTimer();
  // }, [authCtx]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log('##### OrderDetails - useEffect - fetchData #####');
      setIsLoading(true);
      // setRefreshData(false);

      const useReturnedOrderDetailsAsIs = true;

      const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData', responseData);

        const recordDetails = responseData.data.orders[0];

        displayModifyOrderButton =
          userRole === 'User' && recordDetails.status === 'Placed' ? true : false;

        displayReorderButton =
          userRole === 'User' &&
          (recordDetails.status === 'Scheduled' ||
            recordDetails.status === 'Completed' ||
            recordDetails.status === 'Canceled' ||
            recordDetails.status === 'Rejected')
            ? true
            : false;

        // recordDetails.status

        if (useReturnedOrderDetailsAsIs) {
          // console.log('recordDetails');
          // console.log(recordDetails);

          // New Code.
          // if (recordDetails.deliveryDateAndTimeForDisplay === 'NOT SET') {
          //   orderCtx.isReorder = true;
          //   orderCtx.setIsReorder(true);
          // } else {
          //   orderCtx.isReorder = false;
          //   orderCtx.setIsReorder(false);
          // }

          setRecordDetails(recordDetails);
        } else {
          const returnedRecordDetails = {
            orderUuid: recordDetails.orderUuid,
            restaurantUuid: recordDetails.restaurantUuid,
            deliveryLocationUuid: recordDetails.deliveryLocationUuid,
            deliveryCity: recordDetails.deliveryCity,
            deliveryState: recordDetails.deliveryState,
            deliveryZipcode: recordDetails.deliveryZipcode,
            orderNumber: recordDetails.orderNumber,
            orderInstructions: recordDetails.orderInstructions,
            deliveryInstructions: recordDetails.deliveryInstructions,
            teamInstructions: recordDetails.teamInstructions,
            canOrderProceed: recordDetails.canOrderProceed,
            earliestDeliveryDate: recordDetails.earliestDeliveryDate,
            deliveryDate: recordDetails.deliveryDate ? recordDetails.deliveryDate : '',
            deliveryTime: recordDetails.deliveryTime ? recordDetails.deliveryTime : '',
            headcount: recordDetails.headcount,
            subtotal: recordDetails.subtotal
              ? +recordDetails.subtotal.toString().replace(/[","]/g, '')
              : 0,
            orderItemsSubtotal: recordDetails.orderItemsSubtotal
              ? +recordDetails.orderItemsSubtotal.toString().replace(/[","]/g, '')
              : 0,
            additionalCharges: recordDetails.additionalCharges
              ? +recordDetails.additionalCharges.toString().replace(/[","]/g, '')
              : 0,
            teamCharge: recordDetails.teamCharge
              ? +recordDetails.teamCharge.toString().replace(/[","]/g, '')
              : 0,
            deliveryFee: recordDetails.deliveryFee
              ? +recordDetails.deliveryFee.toString().replace(/[","]/g, '')
              : 0,
            tip: recordDetails.tip ? +recordDetails.tip.toString().replace(/[","]/g, '') : 0,
            tax: recordDetails.tax ? +recordDetails.tax.toString().replace(/[","]/g, '') : 0,
            total: recordDetails.total ? +recordDetails.total.toString().replace(/[","]/g, '') : 0,

            status: recordDetails.status,
            restaurantStatus: recordDetails.restaurantStatus,
            statusNotes: recordDetails.statusNotes,
            datePayment: recordDetails.datePayment,
            orderStatus:
              userRole === 'Vendor' &&
              recordDetails.status === 'Placed' &&
              recordDetails.restaurantStatus === 'Accepted'
                ? 'New'
                : recordDetails.status === 'Placed' && recordDetails.restaurantStatus === 'Rejected'
                ? 'Rejected'
                : recordDetails.status === 'Canceled' ||
                  recordDetails.restaurantStatus === 'Canceled'
                ? 'Canceled'
                : userRole === 'User'
                ? recordDetails.status
                : userRole === 'Vendor'
                ? recordDetails.restaurantStatus
                : recordDetails.status,
            restaurantName: recordDetails.restaurantName,
            taxRate: recordDetails.taxRate,
            teamName: recordDetails.teamName,
            deliveryLocation: recordDetails.deliveryLocation,

            deliveryDateAndTimeForDisplay: recordDetails.deliveryDateAndTimeForDisplay,
            datePaymentForDisplay: recordDetails.datePaymentForDisplay,
            subtotalFormatted: recordDetails.subtotalFormatted,
            teamChargeFormatted: recordDetails.teamChargeFormatted,
            restaurantChargeFormatted: recordDetails.restaurantChargeFormatted,
            restaurantsCutFormatted: recordDetails.restaurantsCutFormatted,
            eliteEatsCutFormatted: recordDetails.eliteEatsCutFormatted,
            deliveryFeeFormatted: recordDetails.deliveryFeeFormatted,
            tipFormatted: recordDetails.tipFormatted,
            taxFormatted: recordDetails.taxFormatted,
            totalFormatted: recordDetails.totalFormatted,
            calculatedTotalFormatted: recordDetails.calculatedTotalFormatted,
          };
          // console.log(' -- returnedRecordDetails -- ');
          // console.log(returnedRecordDetails);

          // If the order is scheduled then we should deal with sales tax.
          if (
            returnedRecordDetails.restaurantStatus === 'Scheduled' ||
            returnedRecordDetails.restaurantStatus === 'Completed'
          ) {
            setShowTeamCharge(true);
            setShowTaxInfo(true);
            setShowTotal(true);
          } else {
            setShowTeamCharge(displayTeamCharge);
            setShowTaxInfo(false);
            setShowTotal(false);
          }

          setRecordDetails(returnedRecordDetails);
        }

        setSelectedRestaurantUuid(recordDetails.restaurantUuid);

        const transformedOrderItemRecords = responseData.data.order_items.map((orderItem) => {
          return {
            orderItemUuid: orderItem.orderItemUuid,
            name: orderItem.name,
            description: orderItem.description,
            details: orderItem.details,
            feeds: orderItem.feeds,
            servingSize: orderItem.servingSize,
            price: orderItem.price,
            quantity: orderItem.quantity,
            menuItemCategory: orderItem.menuItemCategory,
            specialInstructions: orderItem.specialInstructions,
            status: orderItem.status,
            subtotal: orderItem.price * orderItem.quantity,
          };
        });

        // console.log('transformedOrderItemRecords');
        // console.log(transformedOrderItemRecords);

        setOrderItems(transformedOrderItemRecords);

        const transformedAdditionalChargesRecords = responseData.data.additional_charges.map(
          (record) => {
            return {
              additionalChargeUuid: record.additionalChargeUuid,
              name: record.name,
              description: record.description,
              price: record.price,
              quantity: record.quantity,
              status: record.status,
              subtotal: record.subtotal,
              subtotalFormatted: record.subtotalFormatted,
            };
          }
        );

        // console.log('transformedAdditionalChargesRecords');
        // console.log(transformedAdditionalChargesRecords);

        setAdditionalCharges(transformedAdditionalChargesRecords);

        if (responseData.data.order_contacts) {
          // console.log('responseData.data.order_contacts');
          // console.table(responseData.data.order_contacts);

          const transformedContacts = responseData.data.order_contacts.map((user) => {
            return {
              userUuid: user.userUuid,
              firstName: user.firstName,
              lastName: user.lastName,
              name: user.firstName + ' ' + user.lastName,
              position: user.position,
              phone: user.phone,
              email: user.email,
              notificationPreference: user.notificationPreference,
              status: user.status,
              roleType: user.roleType,
              orgName: user.orgName ? user.orgName : '',
            };
          });

          // console.log('transformedContacts');
          // console.table(transformedContacts);

          setOrderContacts(transformedContacts);
        }

        if (responseData.data.restaurant) {
          // console.log('responseData.data.restaurant');
          // console.table(responseData.data.restaurant);

          const imageFolder = Constants.IMAGE_FOLDER_PATH;
          // const defaultListImage = imageFolder + 'restaurants/default_list_image.jpg';
          const defaultHeaderImage = imageFolder + 'restaurants/default_header_image.jpg';
          // console.log('defaultListImage = ' + defaultListImage);
          // console.log('imageFolder = ' + imageFolder);
          // console.log('defaultHeaderImage = ' + defaultHeaderImage);

          const restaurant = responseData.data.restaurant ? responseData.data.restaurant : null;

          const transformedRestaurantData = {
            restaurantUuid: restaurant.restaurant_uuid,
            name: restaurant.name,
            description: restaurant.description,
            address:
              restaurant.address2 === '' || restaurant.address2 === null
                ? restaurant.address1
                : restaurant.address1 + ', ' + restaurant.address2,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            zipcode: restaurant.zipcode,
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.food_type,
            // listImage:
            //   restaurant.listImage === '' || restaurant.listImage === null
            //     ? defaultListImage
            //     : baseApiUploadedImagesPath +
            //       restaurant.restaurantUuid +
            //       '/' +
            //       restaurant.listImage,
            // TODO Update this code to use the uploadsFolder.
            headerImage:
              restaurant.header_image === '' || restaurant.header_image === null
                ? defaultHeaderImage
                : baseApiUploadedImagesPath +
                  restaurant.restaurant_uuid +
                  '/' +
                  restaurant.header_image,
            status: restaurant.status,
            leagueExperience: restaurant.league_experience,
            deliveryHours: restaurant.delivery_hours,
            deliveryHoursStart: restaurant.delivery_hours_start,
            deliveryHoursEnd: restaurant.delivery_hours_end,
            orderLeadTime: restaurant.order_lead_time,
            taxRate: restaurant.tax_rate,
            rating: restaurant.rating,
            numberOfRatings: restaurant.number_of_ratings,
            uploadsFolder: restaurant.uploadsFolder,
            isParent: restaurant.isParent,
          };

          // console.log('transformedRestaurantData');
          // console.log(transformedRestaurantData);

          // Don't want to do this until the Continue Order button is clicked.
          setSelectedRestaurant(transformedRestaurantData);
        }

        if (responseData.data.restaurant_contacts) {
          const transformedRestaurantContacts = responseData.data.restaurant_contacts.map(
            (user) => {
              return {
                userUuid: user.userUuid,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.firstName + ' ' + user.lastName,
                position: user.position,
                phone: user.phone,
                email: user.email,
              };
            }
          );

          setRestaurantContacts(transformedRestaurantContacts);
        }

        if (responseData.data.delivery_location) {
          const deliveryLocation = responseData.data.delivery_location;

          let deliveryLocationAddressLine1 = deliveryLocation.address1
            ? deliveryLocation.address1
            : '';
          if (deliveryLocation.address2) {
            deliveryLocationAddressLine1 = deliveryLocationAddressLine1.concat(
              ' ',
              deliveryLocation.address2
            );
          }
          let deliveryLocationAddressLine2 =
            deliveryLocation.city + ', ' + deliveryLocation.state + ', ' + deliveryLocation.zipcode;

          const deliveryLocationForDisplay = {
            name: deliveryLocation.name,
            addressLine1: deliveryLocationAddressLine1,
            addressLine2: deliveryLocationAddressLine2,
          };

          setDeliveryLocationForDisplay(deliveryLocationForDisplay);

          setDeliveryLocation(deliveryLocation);
        }
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    authCtx.displayTaxInfo,
    orderUuid,
    FormatDateForDisplay,
    FormatTimeForDisplay,
    FormatLongDateForDisplay,
    FormatLongDateAndTimeForDisplay,
    refreshData,
    userRole,
    baseApiUploadedImagesPath,
    FormatCurrencyForDisplay,
    history,
    dashboardUrl,
  ]);

  // console.log('### recordDetails.deliveryDateAndTimeForDisplay ###');
  // console.log(recordDetails.deliveryDateAndTimeForDisplay);

  const reorderHandler = () => {
    // console.log('### reorderHandler ###');
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    duplicateOrder();
  };

  const duplicateOrder = async () => {
    const response = await fetch(authCtx.baseApiUrl + 'orders/reorder/' + orderUuid, {
      method: 'GET',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      // await response.json();
      // console.log(responseData);

      if (responseData.data.order_uuid) {
        const newOrderUuid = responseData.data.order_uuid;
        history.replace(dashboardUrl + 'orders/' + newOrderUuid);
      }
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const continueOrderHandler = () => {
    orderCtx.replaceOrderItems(orderItems);
    orderCtx.setOrderDetails(recordDetails);
    orderCtx.setOrderContacts(orderContacts);
    orderCtx.setRestaurant(selectedRestaurant);
    orderCtx.setRestaurantContacts(restaurantContacts);
    orderCtx.setDeliveryLocation(deliveryLocation);

    orderCtx.setIsReorder(false);

    if (selectedRestaurant.name) {
      history.replace('/order/restaurant-menu');
    } else {
      history.replace('/order/select-restaurant');
    }
  };

  // Test Code.
  // console.log('orderCtx.isReorder = ' + orderCtx.isReorder);

  const setDeliveryDateAndTimeHandler = () => {
    // console.log('### setDeliveryDateAndTimeHandler ###');

    orderCtx.replaceOrderItems(orderItems);
    orderCtx.setOrderDetails(recordDetails);
    orderCtx.setOrderContacts(orderContacts);
    orderCtx.setRestaurant(selectedRestaurant);
    orderCtx.setRestaurantContacts(restaurantContacts);
    orderCtx.setDeliveryLocation(deliveryLocation);

    orderCtx.setIsReorder(true);

    // history.replace('/order/reorder');
    // history.replace('/order/select-restaurant');
    history.replace('/order/reorder-set-delivery');
  };

  const deleteOrderHandler = (orderUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteData(orderUuid);
    setNotify({ isOpen: true, message: 'Order Successfully Deleted', type: 'error' });
  };

  const deleteData = async (orderUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);

      history.replace(dashboardUrl + 'orders');
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const closeStatusNotesFormHandler = (dataRefresh = false) => {
    setOpenRejectOrderForm(false);
    setRefreshData(dataRefresh);
  };

  const refreshDataHandler = () => {
    // setRefreshData(true);
    const timestamp = new Date().getTime();
    setRefreshData(timestamp);
  };

  // Test Function to send out order notifications after data has been updated and returned.
  // This is being done to speed things up.
  const sendOrderNotifications = async () => {
    // console.log('sendOrderNotifications - orderUuid = ' + orderUuid);

    // setNotify({ isOpen: true, message: 'Updating order information.', type: 'info' });

    const apiURL = authCtx.baseApiUrl + 'orders/ordernotifications/' + orderUuid;
    const apiMethod = 'GET';

    const response = await fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);
    } else {
      setIsError(true);
      // console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while sending Order Notifications.',
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  const updateOrderStatusValues = async (status) => {
    // console.log('updateOrderStatusValues - orderUuid = ' + orderUuid + ' - status = ' + status);

    setNotify({ isOpen: true, message: 'Updating order information.', type: 'info' });

    const sendNotifications = status === 'Completed' ? false : true;

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';
    let apiBody = { status: status };
    if (userRole === 'Vendor') {
      apiBody = { restaurant_status: status };
    }
    if (status === 'Scheduled') {
      apiBody = { status: status, restaurant_status: status };
    }
    if (status === 'Canceled' || status === 'Rejected') {
      apiBody = { status: status, restaurant_status: status };
    }

    const response = await fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify(apiBody),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);
      // setRefreshData(true);
      refreshDataHandler();

      if (sendNotifications) {
        sendOrderNotifications();
      }
    } else {
      setIsError(true);
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while saving Order Information.  Please try again.',
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  const acceptOrderHandler = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    updateOrderStatusValues('Accepted');
  };

  const rejectOrderHandler = () => {
    // setConfirmDialog({ ...confirmDialog, isOpen: false });
    // updateOrderStatusValues('Rejected');
    setOpenRejectOrderForm(true);
  };

  const cancelOrderHandler = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    updateOrderStatusValues('Canceled', 'Canceled');
  };

  const completeOrderHandler = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    updateOrderStatusValues('Completed', 'Completed');
  };

  const editOrderHandler = () => {
    history.replace('/order/select-restaurant');
  };

  const toggleShowTeamContacts = () => {
    setShowTeamContacts((current) => !current);
    const newLabel =
      showTeamContactsLabel === 'Show Team Contacts' ? 'Hide Team Contacts' : 'Show Team Contacts';
    setShowTeamContactsLabel(newLabel);
  };

  const toggleShowRestaurantContacts = () => {
    setShowRestaurantContacts((current) => !current);
    const newLabel =
      showRestaurantContactsLabel === 'Show Restaurant Contacts'
        ? 'Hide Restaurant Contacts'
        : 'Show Restaurant Contacts';
    setShowRestaurantContactsLabel(newLabel);
  };

  const showDeliveryDetails = () => {
    setOpenPopupDeliveryDetails((current) => !current);
  };

  const printOrderDetailsHandler = () => {
    // console.log('printOrderDetailsHandler');
    setOpenPopupPrintOrderDetails((current) => !current);
  };

  const printOrderDetailsSubtotalHandler = () => {
    console.log('printOrderDetailsSubtotalHandler');
    setOpenPopupPrintOrderDetailsSubtotal((current) => !current);
  };

  const printOrderReceiptHandler = () => {
    console.log('printOrderReceiptHandler');
    setOpenPopupPrintOrderReceipt((current) => !current);
  };

  const printOrderForKitchenHandler = () => {
    console.log('printOrderForKitchenHandler');
    setOpenPopupPrintOrderForKitchen((current) => !current);
  };

  // Admin only function
  const calculateTotalsHandler = async () => {
    const response = await fetch(authCtx.baseApiUrl + 'adminfunctions/calculateOrderTotals', {
      method: 'POST',
      body: JSON.stringify({
        order_uuid: orderUuid,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log(responseData);
      await response.json();

      // setRefreshData(true);
      refreshDataHandler();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const showPaymentForm = () => {
    setOpenPopupPaymentForm((current) => !current);
  };

  // console.log('orderItems', orderItems);
  // console.log('additionalCharges', additionalCharges);
  // console.log('selectedRestaurant', selectedRestaurant);
  // console.log('recordDetails', recordDetails);
  // console.log('userRole', userRole);

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <>
          <Box className='title-and-button-holder'>
            <Box>
              <Link to={dashboardUrl + 'orders'} className='title-link'>
                Orders
              </Link>
              <Typography variant='labelInline' component='span' sx={{ px: 2 }}>
                &gt;
              </Typography>
              <Typography variant='labelInline' component='span'>
                {/* {recordDetails.orderStatus} */}
                {recordDetails.overallOrderStatus}
              </Typography>

              {!recordDetails.canOrderProceed ? (
                <Typography variant='labelInline' component='span' color='error'>
                  ({orderLeadTimeExceededStatusValue})
                </Typography>
              ) : null}

              <Typography variant='labelInline' component='span' sx={{ px: 2 }}>
                &gt;
              </Typography>
              <Typography variant='labelInline' component='span'>
                {recordDetails.orderNumber}
              </Typography>
            </Box>

            {userRole !== 'Admin' ? (
              <Box component='div' className='button-holder-no-pb'>
                {recordDetails.deliveryDateAndTimeForDisplay !== 'NOT SET' ? (
                  <>
                    {userRole !== 'User' &&
                    recordDetails.canOrderProceed &&
                    // recordDetails.restaurantStatus === 'New' &&
                    (recordDetails.restaurantStatus === 'New' ||
                      recordDetails.restaurantStatus === 'Modified') && // New Code
                    recordDetails.orderStatus !== 'Canceled' ? (
                      <Button
                        variant='contained'
                        color='accent'
                        size='large'
                        sx={{ px: '3rem' }}
                        // onClick={acceptOrderHandler}
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure you want to accept this order?',
                            subTitle:
                              'The Team will be notified right away that you have accepted the Order and are waiting for payment.',
                            type: 'Accept Order',
                            onConfirm: () => {
                              acceptOrderHandler();
                            },
                          });
                        }}
                      >
                        Request Payment
                      </Button>
                    ) : null}

                    {recordDetails.canOrderProceed &&
                    userRole === 'User' &&
                    recordDetails.status === 'Placed' &&
                    recordDetails.restaurantStatus === 'Accepted' ? (
                      <Button variant='contained' color='accent' onClick={() => showPaymentForm()}>
                        Pay for Order
                      </Button>
                    ) : null}

                    {userRole === 'User' &&
                    recordDetails.status === paymentNotConfirmedStatusValue &&
                    recordDetails.restaurantStatus === 'Accepted' ? (
                      <Typography variant='h6'>Payment Made: Waiting for Confirmation</Typography>
                    ) : null}

                    {displayModifyOrderButton ? (
                      <Button
                        variant='contained'
                        color='highlight'
                        // onClick={() => continueOrderHandler()}
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure you want to Modify this Order?',
                            subTitle:
                              'Please message your fueling partner notifying them of any modifications.  Your fueling partner may reject any modifications made within 72 hours of the delivery date.',
                            type: 'General',
                            onConfirm: () => {
                              continueOrderHandler();
                            },
                          });
                        }}
                      >
                        Modify Order
                      </Button>
                    ) : null}

                    {displayReorderButton ? (
                      <Button
                        variant='contained'
                        color='accent'
                        size='custom'
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure you want to Reorder this Order?',
                            // subTitle: 'You will have to select a Delivery Data and Time.',
                            type: 'General',
                            onConfirm: () => {
                              reorderHandler();
                            },
                          });
                        }}
                      >
                        Reorder
                      </Button>
                    ) : null}

                    {recordDetails.status === 'Draft' && userRole === 'User' ? (
                      <Button
                        variant='contained'
                        color='accent'
                        size='custom'
                        onClick={continueOrderHandler}
                      >
                        Continue Order
                      </Button>
                    ) : null}

                    {(recordDetails.status === 'Draft' && userRole === 'User') ||
                    recordDetails.status === 'Canceled' ||
                    recordDetails.restaurantStatus === 'Rejected' ? (
                      <Button
                        variant='contained'
                        color='error'
                        size='custom'
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: 'Are you sure you want to delete this Order.',
                            subTitle: 'It will be permanently deleted.',
                            type: 'General',
                            onConfirm: () => {
                              deleteOrderHandler(orderUuid);
                            },
                          });
                        }}
                      >
                        {recordDetails.status === 'Draft' ? 'Delete Draft' : 'Delete Order'}
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <Button
                    variant='contained'
                    color='accent'
                    size='custom'
                    onClick={setDeliveryDateAndTimeHandler}
                  >
                    Set Delivery & Menu
                  </Button>
                )}

                {userRole !== 'User' &&
                (recordDetails.restaurantStatus === 'New' ||
                  recordDetails.restaurantStatus === 'Modified' || // New Code
                  recordDetails.restaurantStatus === 'Accepted') &&
                recordDetails.orderStatus !== 'Canceled' ? (
                  <Button
                    variant='contained'
                    color='error'
                    size='large'
                    sx={{ px: '3rem' }}
                    onClick={rejectOrderHandler}
                  >
                    Reject Order
                  </Button>
                ) : null}

                {displayEditOrderButton &&
                userRole === 'User' &&
                recordDetails.status === 'Draft' ? (
                  <Button variant='contained' color='accent' onClick={() => editOrderHandler()}>
                    Edit Order
                  </Button>
                ) : null}

                {userRole !== 'Vendor' &&
                recordDetails.status === 'Placed' &&
                recordDetails.restaurantStatus !== 'Rejected' ? (
                  <Button
                    variant='contained'
                    color='error'
                    size='custom'
                    // onClick={cancelOrderHandler}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure you want to CANCEL this order?',
                        subTitle:
                          "Order cancellations are subject to the fueling partner's individual policies and procedures. Please contact them for further information.",
                        type: 'General',
                        onConfirm: () => {
                          cancelOrderHandler();
                        },
                      });
                    }}
                  >
                    Cancel Order
                  </Button>
                ) : null}

                {(userRole === 'User' && recordDetails.status === 'Scheduled') ||
                (userRole === 'Vendor' && recordDetails.restaurantStatus === 'Scheduled') ? (
                  <Button
                    variant='contained'
                    color='accent'
                    size='custom'
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure you want to complete this Order?',
                        type: 'General',
                        onConfirm: () => {
                          completeOrderHandler();
                        },
                      });
                    }}
                  >
                    Order Completed
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </Box>
          <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem' }}>
            <Box component='div' className={classes['details-page-grid-layout']}>
              {!isLoading && !isError ? (
                <Box component='div' className={classes['details-page-content-grid-layout']}>
                  <Box component='div' className={classes['details-page-content-holder']}>
                    <Box component='div' className='flex-wrap-container'>
                      <Box>
                        <Typography variant='h9'>Delivery Date</Typography>
                        {recordDetails.deliveryDateAndTimeForDisplay ? (
                          <Typography variant='labelLarge'>
                            {recordDetails.deliveryDateAndTimeForDisplay}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box>
                        <Typography variant='h9'>Headcount: {recordDetails.headcount}</Typography>
                      </Box>
                    </Box>

                    <Box component='div' sx={{ pt: '2rem' }} className='flex-wrap-container'>
                      <div>
                        <Typography variant='h8'>Delivery Information</Typography>
                        <Typography variant='labelLarge'>{recordDetails.teamName}</Typography>

                        {deliveryLocationForDisplay.name ? (
                          <>
                            <Typography>{deliveryLocationForDisplay.name}</Typography>
                            <Typography>{deliveryLocationForDisplay.addressLine1}</Typography>
                            <Typography>{deliveryLocationForDisplay.addressLine2}</Typography>
                          </>
                        ) : null}

                        {!deliveryLocationForDisplay.name ? (
                          <Typography>{recordDetails.deliveryCity}</Typography>
                        ) : null}

                        <Box className='pt-20'>
                          <Button
                            variant='text'
                            onClick={toggleShowTeamContacts}
                            size='small'
                            className='text-button'
                            sx={{ p: '0', m: '0' }}
                          >
                            {showTeamContactsLabel}
                          </Button>
                        </Box>
                        <Collapse in={showTeamContacts}>
                          <Box>
                            {Array.isArray(orderContacts) && orderContacts.length > 0 ? (
                              <Box component='div' sx={{ py: '2rem' }}>
                                {orderContacts.map((record, index) => (
                                  <Box key={index} sx={{ pb: '1rem' }}>
                                    <div>{record.name}</div>
                                    <div>{record.position}</div>
                                    <div>{record.phone}</div>
                                    <div>{record.email}</div>
                                  </Box>
                                ))}
                              </Box>
                            ) : (
                              <Box className='pt-20 small-text'>Contacts Not Set</Box>
                            )}
                          </Box>
                        </Collapse>
                      </div>

                      <div>
                        <Typography variant='h8'>Restaurant</Typography>
                        <Typography variant='labelLarge'>{selectedRestaurant.name}</Typography>
                        <Typography>{selectedRestaurant.address}</Typography>
                        <Typography>{`${selectedRestaurant.city}, ${selectedRestaurant.state} ${selectedRestaurant.zipcode}`}</Typography>

                        {selectedRestaurant.phone ? (
                          <Typography>{selectedRestaurant.phone}</Typography>
                        ) : null}

                        {selectedRestaurant.email ? (
                          <Typography>{selectedRestaurant.email}</Typography>
                        ) : null}

                        {displayRestaurantContacts ? (
                          <>
                            <Box className='pt-20'>
                              <Button
                                variant='text'
                                onClick={toggleShowRestaurantContacts}
                                size='small'
                                className='text-button'
                                sx={{ p: '0', m: '0' }}
                              >
                                {showRestaurantContactsLabel}
                              </Button>
                            </Box>

                            <Collapse in={showRestaurantContacts}>
                              <Box>
                                {Array.isArray(restaurantContacts) &&
                                restaurantContacts.length > 0 ? (
                                  <Box component='div' sx={{ py: '2rem' }}>
                                    {restaurantContacts.map((record, index) => (
                                      <Box key={index} sx={{ pb: '1rem' }}>
                                        <div>{record.name}</div>
                                        <div>{record.position}</div>
                                        <div>{record.phone}</div>
                                        <div>{record.email}</div>
                                      </Box>
                                    ))}
                                  </Box>
                                ) : (
                                  <Box className='pt-20'>No Contacts Found</Box>
                                )}
                              </Box>
                            </Collapse>
                          </>
                        ) : null}
                      </div>
                    </Box>

                    {orderItems.length > 0 ? (
                      <Box sx={{ py: '30px' }} className='button-holder'>
                        <Button size='small' variant='contained' onClick={showDeliveryDetails}>
                          Delivery Details
                        </Button>

                        {displayPrintOrderReceiptButton ? (
                          <Button
                            size='small'
                            variant='contained'
                            onClick={printOrderReceiptHandler}
                          >
                            Print Order Receipt
                          </Button>
                        ) : null}

                        <Button size='small' variant='contained' onClick={printOrderDetailsHandler}>
                          Print Order Details
                        </Button>

                        {displayPrintOrderDetailsSubtotalsOnlyButton ? (
                          <Button
                            size='small'
                            variant='contained'
                            onClick={printOrderDetailsSubtotalHandler}
                          >
                            Print Order Details (Subtotals Only)
                          </Button>
                        ) : null}

                        {displayPrintOrderForKitchenButton && userRole === 'Vendor' ? (
                          <Button
                            size='small'
                            variant='contained'
                            onClick={printOrderForKitchenHandler}
                          >
                            Print Order for the Kitchen
                          </Button>
                        ) : null}
                      </Box>
                    ) : null}

                    {orderItems.length > 0 ? (
                      <Box sx={{ py: '20px' }}>
                        <Typography variant='h8'>Ordered Items</Typography>

                        <OrderDetailsOrderedItems
                          recordDetails={recordDetails}
                          orderItems={orderItems}
                          additionalCharges={additionalCharges}
                          userRole={userRole}
                          setIsLoading={setIsLoading}
                          setIsError={setIsError}
                          refreshDataHandler={refreshDataHandler}
                        />
                      </Box>
                    ) : null}

                    {orderItems.length > 0 ? (
                      <>
                        <Divider />
                        <OrderDetailsTotalArea
                          orderUuid={orderUuid}
                          deliveryFee={recordDetails.deliveryFeeFormatted}
                          deliveryFeeUnformatted={recordDetails.deliveryFee}
                          tip={recordDetails.tipFormatted}
                          teamCharge={recordDetails.teamChargeFormatted}
                          teamChargeRate={teamChargeRate}
                          teamChargeLabel={teamChargeLabel}
                          restaurantsCut={recordDetails.restaurantsCutFormatted}
                          eliteEatsCut={recordDetails.eliteEatsCutFormatted}
                          eliteEatsPayoutMainLabel={eliteEatsPayoutMainLabel}
                          eliteEatsPayoutSubLabel={eliteEatsPayoutSubLabel}
                          deliveryFeeLabel={deliveryFeeLabel}
                          orderSubtotal={recordDetails.subtotalFormatted}
                          orderTax={recordDetails.taxFormatted}
                          orderTotal={recordDetails.calculatedTotalFormatted}
                          // orderTotal={recordDetails.totalFormatted}
                          datePayment={recordDetails.datePayment}
                          datePaymentForDisplay={recordDetails.datePaymentForDisplay}
                          // transaction={transactionData}
                          // status={recordDetails.status}
                          restaurantStatus={recordDetails.restaurantStatus}
                          userRole={userRole}
                          refreshData={refreshDataHandler}
                        />
                      </>
                    ) : null}

                    {displayOrderTotalsTable ? (
                      <Box sx={{ py: 2 }}>
                        <table className={classes['table-details']}>
                          <tbody>
                            {showTeamCharge &&
                            userRole !== 'Vendor' &&
                            recordDetails.teamCharge > 0 ? (
                              <tr>
                                <td>{teamChargeLabel}</td>
                                <td className={classes['number']}>
                                  {FormatCurrencyForDisplay(recordDetails.teamCharge)}
                                </td>
                              </tr>
                            ) : null}

                            <tr>
                              <td>Subtotal **</td>
                              <td className={classes['number']}>
                                {FormatCurrencyForDisplay(+recordDetails.subtotal)}
                              </td>
                            </tr>

                            {showTaxInfo ? (
                              <tr>
                                {/* <td>Sales Tax ({recordDetails.taxRate}%)</td> */}
                                <td>Sales Tax</td>
                                <td className={classes['number']}>
                                  {FormatCurrencyForDisplay(recordDetails.tax)}
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>

                        {showTotal ? (
                          <>
                            <Divider sx={{ pt: '1rem', mb: '1rem' }} />
                            <table className={classes['table-details']}>
                              <tbody>
                                <tr className={classes['totals-row']}>
                                  <td>Total</td>
                                  <td className={classes['number']}>
                                    {FormatCurrencyForDisplay(recordDetails.total)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : null}
                      </Box>
                    ) : null}

                    {userRole === 'Admin' && displayCaculateTotalsButton ? (
                      <Box sx={{ pt: '2rem', pb: '1rem' }} className='button-holder'>
                        <Button
                          size='small'
                          variant='contained'
                          color='error'
                          onClick={calculateTotalsHandler}
                        >
                          Calculate Totals
                        </Button>
                      </Box>
                    ) : null}
                  </Box>

                  <Box
                    component='div'
                    className={classes['details-page-content-holder']}
                    sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                  >
                    <FileAttachmentList
                      orderUuid={orderUuid}
                      displayHeaderText={true}
                      key={refreshData}
                    />

                    {recordDetails.status === 'Completed' ? (
                      <Box>
                        <RestaurantReview
                          orderUuid={orderUuid}
                          restaurantUuid={selectedRestaurantUuid}
                          userRole={userRole}
                        />
                      </Box>
                    ) : null}

                    <Box>
                      <OrderMessages
                        orderUuid={orderUuid}
                        status={recordDetails.status}
                        userRole={authCtx.userRole}
                        checkout={false}
                      />
                    </Box>

                    {(recordDetails.deliveryInstructions !== '' &&
                      recordDetails.deliveryInstructions !== null) ||
                    (deliveryLocation.deliveryInstructions !== '' &&
                      deliveryLocation.deliveryInstructions !== null) ? (
                      <Box sx={{ pt: '20px' }}>
                        <Typography variant='h8'>Delivery Instructions</Typography>
                        <Typography component='pre'>
                          {recordDetails.deliveryInstructions
                            ? recordDetails.deliveryInstructions
                            : deliveryLocation.deliveryInstructions}
                        </Typography>
                      </Box>
                    ) : null}

                    {recordDetails.teamInstructions !== '' &&
                    recordDetails.teamInstructions !== null ? (
                      <Box sx={{ pt: '20px' }}>
                        <Typography variant='h8'>Team Instructions</Typography>
                        <Typography component='pre'>{recordDetails.teamInstructions}</Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : null}

              <Popup
                openPopup={openRejectOrderForm}
                setOpenPopup={setOpenRejectOrderForm}
                maxWidth='md'
                title='Are you sure you want to Reject this Order?'
              >
                <OrderStatusNotesForm
                  orderUuid={orderUuid}
                  statusForUpdate='Rejected'
                  statusNotes={recordDetails.statusNotes}
                  onClose={closeStatusNotesFormHandler}
                />
              </Popup>

              <Popup
                openPopup={openPopupDeliveryDetails}
                setOpenPopup={setOpenPopupDeliveryDetails}
                maxWidth='md'
                title={'Delivery Details for Order:  ' + recordDetails.orderNumber}
              >
                <DeliveryDetails
                  deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
                  deliveryLocationForDisplay={deliveryLocationForDisplay}
                  recordDetails={recordDetails}
                  orderContacts={orderContacts}
                />
              </Popup>

              <Popup
                openPopup={openPopupPrintOrderDetails}
                setOpenPopup={setOpenPopupPrintOrderDetails}
                maxWidth='md'
                title={'Order Number: ' + recordDetails.orderNumber}
              >
                <PrintOrderDetails
                  deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
                  deliveryLocationForDisplay={deliveryLocationForDisplay}
                  recordDetails={recordDetails}
                  orderContacts={orderContacts}
                  orderItems={orderItems}
                  additionalCharges={additionalCharges}
                  selectedRestaurant={selectedRestaurant}
                  userRole={userRole}
                />
              </Popup>

              <Popup
                openPopup={openPopupPrintOrderDetailsSubtotal}
                setOpenPopup={setOpenPopupPrintOrderDetailsSubtotal}
                maxWidth='md'
                title={'Order Number: ' + recordDetails.orderNumber}
              >
                <PrintOrderDetailsSubtotal
                  deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
                  deliveryLocationForDisplay={deliveryLocationForDisplay}
                  recordDetails={recordDetails}
                  orderContacts={orderContacts}
                  orderItems={orderItems}
                  additionalCharges={additionalCharges}
                  selectedRestaurant={selectedRestaurant}
                  authCtx={authCtx}
                />
              </Popup>

              <Popup
                openPopup={openPopupPrintOrderReceipt}
                setOpenPopup={setOpenPopupPrintOrderReceipt}
                maxWidth='md'
                title={'Order Number: ' + recordDetails.orderNumber}
              >
                <PrintOrderReceipt
                  deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
                  deliveryLocationForDisplay={deliveryLocationForDisplay}
                  recordDetails={recordDetails}
                  orderContacts={orderContacts}
                  orderItems={orderItems}
                  additionalCharges={additionalCharges}
                  selectedRestaurant={selectedRestaurant}
                  authCtx={authCtx}
                />
              </Popup>

              <Popup
                openPopup={openPopupPrintOrderForKitchen}
                setOpenPopup={setOpenPopupPrintOrderForKitchen}
                maxWidth='md'
                title={'Order Number: ' + recordDetails.orderNumber}
              >
                <PrintOrderForKitchen
                  deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
                  deliveryLocationForDisplay={deliveryLocationForDisplay}
                  recordDetails={recordDetails}
                  orderContacts={orderContacts}
                  orderItems={orderItems}
                  additionalCharges={additionalCharges}
                  selectedRestaurant={selectedRestaurant}
                  authCtx={authCtx}
                />
              </Popup>

              <Popup
                openPopup={openPopupPaymentForm}
                setOpenPopup={setOpenPopupPaymentForm}
                maxWidth='md'
                title='Pay for Order'
              >
                <PaymentForm subtotal={recordDetails.subtotal} orderDetails={recordDetails} />
              </Popup>

              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmationDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </Box>
          </Paper>
        </>
      ) : (
        <Box>
          <Typography variant='h2' component='h2'>
            Loading Order Details...
          </Typography>
        </Box>
      )}
    </>
  );
};

export default OrderDetails;
