import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import FormControls from '../MUI/FormControls/FormControls';

const VendorReferralCard = (props) => {
  const { record, setConfirmDialog, deleteDataHandler } = props;

  // console.log('record');
  // console.table(record);

  return (
    <Box className='flex-item-test'>
      <Paper elevation={4} sx={{ p: '2rem', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h2' component='h2'>
            {record.name}
          </Typography>

          <FormControls.ActionButtonControl
            color='error'
            toolTip='Delete Record'
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure you want to delete this record.',
                subTitle: 'It will be permanently deleted.',
                onConfirm: () => {
                  deleteDataHandler(record.vendorReferralUuid);
                },
              });
            }}
          >
            <DeleteIcon sx={{ fontSize: '1.5rem' }} />
          </FormControls.ActionButtonControl>
        </Box>

        {record.location ? (
          <Box sx={{ pb: '1rem' }}>
            <Typography variant='body1' component='div'>
              {record.location}
            </Typography>
          </Box>
        ) : null}

        {record.contactName ? (
          <Typography variant='label' component='div' sx={{ pb: '1rem' }}>
            {record.contactName}
            {record.contactPosition ? (
              <Typography component='span' sx={{ pl: '2rem' }}>
                {'(' + record.contactPosition + ')'}
              </Typography>
            ) : null}

            {record.phone ? <Typography component='div'>{record.phone}</Typography> : null}

            {record.email ? <Typography component='div'>{record.email}</Typography> : null}
          </Typography>
        ) : null}

        {record.notes ? <Typography component='div'>{record.notes}</Typography> : null}
      </Paper>
    </Box>
  );
};

export default VendorReferralCard;
