import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import RestaurantListLocationsTable from './RestaurantListLocationsTable';
// import classes from '../CSS/styled-table.module.css';

const RestaurantList = () => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;

  // Testing
  // Todo:  What is this retaurantUuid used for?
  const restaurantUuid = authCtx.selectedRestaurantUuid;

  const displayCityFilter = false;

  // Testing
  // console.log(' -- RestaurantList -- ');
  // console.log(' - restaurantUuid = ' + restaurantUuid);

  const history = useHistory();

  const [restaurants, setRestaurants] = useState([]);
  const [displayedRestaurants, setDisplayedRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [foodTypes, setFoodTypes] = useState([]);

  const cityFilterRef = useRef();
  const stateFilterRef = useRef();
  const foodTypeFilterRef = useRef();
  const statusFilterRef = useRef();
  const searchRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    const apiUrl = authCtx.baseApiUrl + 'restaurants';

    const fetchData = async () => {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data);

        const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
          return {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            description: restaurant.description,
            // address:
            //   restaurant.address2 === ''
            //     ? restaurant.address1
            //     : restaurant.address1 + ', ' + restaurant.address2,
            address:
              restaurant.address2 === '' || restaurant.address2 === null
                ? restaurant.address1
                : restaurant.address1 + ', ' + restaurant.address2,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            foodType: restaurant.foodType,
            status: restaurant.status,
            headerImage: restaurant.headerImage,
            uploadsFolder: restaurant.uploadsFolder,
            paymentAccountStatus: restaurant.paymentAccountStatus
              ? restaurant.paymentAccountStatus
              : 'Not Setup',
            isParent: restaurant.isParent,
          };
        });

        setRestaurants(transformedRestaurants);

        const filteredRestaurants = transformedRestaurants.filter(
          (restaurant) => restaurant.status === 'Active'
        );

        setDisplayedRestaurants(filteredRestaurants);

        const uniqueCities = responseData.data.restaurant_cities;
        const uniqueStates = responseData.data.restaurant_states;
        const uniqueFoodTypes = responseData.data.restaurant_food_types;

        setCities(uniqueCities);
        setStates(uniqueStates);
        setFoodTypes(uniqueFoodTypes);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, dashboardUrl, history]);

  const updateSelectedRestaurantHandler = (uuid, name, city, state) => {
    // console.log('updateSelectedRestaurantHandler - uuid = ' + uuid + ' -- name = ' + name);
    authCtx.updateSelectedRestaurantValues(uuid, name, city, state);
  };

  const displayRestaurantMenuHandler = (restaurant_uuid) => {
    console.log('setSelectedRestaurant - restaurant_uuid = ' + restaurant_uuid);
    // updateSelectedRestaurantValues
  };

  const clearFilterHandler = () => {
    if (cityFilterRef.current) {
      cityFilterRef.current.value = 'Show All';
    }
    stateFilterRef.current.value = 'Show All';
    foodTypeFilterRef.current.value = 'Show All';
    if (statusFilterRef.current) {
      statusFilterRef.current.value = 'Active';
    }

    // if (searchRef.current) {
    //   searchRef.current.value = '';
    // }

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let cityFilter = cityFilterRef.current ? cityFilterRef.current.value : 'Show All';
    let stateFilter = stateFilterRef.current.value;
    let foodTypeFilter = foodTypeFilterRef.current.value;
    let statusFilter = statusFilterRef.current ? statusFilterRef.current.value : 'Active';

    let searchValue = searchRef.current ? searchRef.current.value.toLowerCase() : '';

    // console.log(' -- filterDisplayedLocations -- ');
    // console.log({ cityFilter: cityFilter, stateFilter: stateFilter, statusFilter: statusFilter });

    // if (cityFilter === 'Show All' && stateFilter === 'Show All' && statusFilter === 'Show All') {
    if (
      cityFilter === 'Show All' &&
      stateFilter === 'Show All' &&
      foodTypeFilter === 'Show All' &&
      statusFilter === 'Show All' &&
      searchValue === ''
    ) {
      setDisplayedRestaurants(restaurants);
    } else {
      let filteredRecords = restaurants;

      // console.log('filteredRecords', filteredRecords);

      if (cityFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.city === cityFilter);
      }

      if (stateFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.state === stateFilter);
      }

      if (foodTypeFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.foodType === foodTypeFilter);
      }

      // console.log(' -- foodTypeFilter = ' + foodTypeFilter);

      if (statusFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
      }

      if (searchValue !== '') {
        filteredRecords = filteredRecords.filter(
          (record) =>
            (record.name !== null && record.name.toLowerCase().includes(searchValue)) ||
            (record.city !== null && record.city.toLowerCase().includes(searchValue)) ||
            (record.address1 !== null && record.address1.toLowerCase().includes(searchValue)) ||
            (record.address2 !== null && record.address2.toLowerCase().includes(searchValue))
        );
      }

      setDisplayedRestaurants(filteredRecords);
    }
  };

  const clearSearchHandler = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    filterDisplayedRecords();
  };

  const copyMenuHandler = () => {
    // console.log('### copyMenuHandler ###');
    history.push(dashboardUrl + 'restaurants/copy-menu');
  };

  return (
    <Box className='page-min-width'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && restaurants.length === 0 ? (
        <WorkflowMessage type='error' message='No Restaurants Found...' />
      ) : null}

      <Box className='title-and-button-holder'>
        <Typography variant='h1' component='h1'>
          Restaurants
        </Typography>
        {/* {!isLoading && !isError && canAddDeliveryLocation ? (
            <Box className='button-holder-no-pb'>
              <Button
                variant='contained'
                size='custom'
                color='accent'
                onClick={addNewHandler}
                autoFocus
              >
                Add New Delivery Location
              </Button>
            </Box>
          ) : null} */}
      </Box>

      {!isLoading && !isError ? (
        <Box className='mb-20'>
          <Box>
            <label htmlFor='search'>
              Search <span className='small-text'>(by Name, Address, City)</span>
            </label>
          </Box>

          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              minWidth: '300px',
              height: '40px',
              // ml: '10px',
              // border: '1px solid red',
            }}
          >
            <FormControls.InputControl
              type='text'
              name='search'
              id='search'
              // label='Search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ flexGrow: '1', mr: '2rem' }}
              onChange={filterDisplayedRecords}
              inputRef={searchRef}
            />
            <FormControls.ButtonControl
              name='clearSearch'
              text='Clear'
              variant='contained'
              size='small'
              endIcon={<SearchIcon />}
              onClick={clearSearchHandler}
              sx={{ p: '2rem', fontWeight: 'bold', height: '4rem' }}
            />
          </Box>
        </Box>
      ) : null}

      {!isLoading && !isError ? (
        <Box className='flex-between-container'>
          <Box className='button-holder'>
            {displayCityFilter ? (
              <Box>
                <Box>
                  <label htmlFor='filter-by-city'>City</label>
                </Box>
                {displayListFilterSelect(
                  'filter-by-city',
                  cityFilterRef,
                  filterDisplayedRecords,
                  cities
                )}
              </Box>
            ) : null}

            <Box>
              <Box>
                <label htmlFor='filter-by-state'>State</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-state',
                stateFilterRef,
                filterDisplayedRecords,
                states
              )}
            </Box>

            <Box>
              <Box>
                <label htmlFor='filter-by-food-type'>Food Type</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-food-type',
                foodTypeFilterRef,
                filterDisplayedRecords,
                foodTypes
              )}
            </Box>

            {userRole !== 'User' ? (
              <Box>
                <Box>
                  <label htmlFor='filter-by-status'>Status</label>
                </Box>
                {displayListFilterSelect(
                  'filter-by-status',
                  statusFilterRef,
                  filterDisplayedRecords,
                  Constants.STATUS_ARRAY_WITH_NEW,
                  'Active'
                )}
              </Box>
            ) : null}

            <Box className='align-bottom'>
              <button className='small' onClick={clearFilterHandler}>
                Clear Filters
              </button>
            </Box>
          </Box>

          {userRole === 'Admin' ? (
            <Box className='align-bottom pb-20'>
              <button className='red' onClick={copyMenuHandler}>
                Copy Menu
              </button>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {!isLoading && !isError && restaurants.length > 0 && (
        <RestaurantListLocationsTable
          restaurantUuid={restaurantUuid}
          restaurants={displayedRestaurants}
          updateSelectedRestaurant={updateSelectedRestaurantHandler}
          onDisplayRestaurantMenu={displayRestaurantMenuHandler}
        />
      )}
    </Box>
  );
};

export default RestaurantList;
