import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import { useFormatData } from '../Hooks/useFormatData';
import WorkflowMessage from '../UI/WorkflowMessage';
import PrintOrderDetails from '../ReactPdf/PrintOrderDetails';

const OrderDetailsPrintout = ({ orderUuid }) => {
  const authCtx = useContext(AuthContext);

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const [recordDetails, setRecordDetails] = useState([]);
  const [orderContacts, setOrderContacts] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});
  const [deliveryLocationForDisplay, setDeliveryLocationForDisplay] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const {
    FormatDateForDisplay,
    FormatTimeForDisplay,
    FormatLongDateForDisplay,
    FormatLongDateAndTimeForDisplay,
  } = useFormatDateAndTime();

  const { FormatCurrencyForDisplay } = useFormatData();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        const recordDetails = responseData.data.orders[0];

        setRecordDetails(recordDetails);

        const transformedOrderItemRecords = responseData.data.order_items.map((orderItem) => {
          return {
            orderItemUuid: orderItem.orderItemUuid,
            name: orderItem.name,
            description: orderItem.description,
            details: orderItem.details,
            feeds: orderItem.feeds,
            servingSize: orderItem.servingSize,
            price: orderItem.price,
            quantity: orderItem.quantity,
            menuItemCategory: orderItem.menuItemCategory,
            specialInstructions: orderItem.specialInstructions,
            status: orderItem.status,
            subtotal: orderItem.price * orderItem.quantity,
          };
        });

        // console.log('transformedOrderItemRecords');
        // console.log(transformedOrderItemRecords);

        setOrderItems(transformedOrderItemRecords);

        const transformedAdditionalChargesRecords = responseData.data.additional_charges.map(
          (record) => {
            return {
              additionalChargeUuid: record.additionalChargeUuid,
              name: record.name,
              description: record.description,
              price: record.price,
              quantity: record.quantity,
              status: record.status,
              subtotal: record.subtotal,
              subtotalFormatted: record.subtotalFormatted,
            };
          }
        );

        // console.log('transformedAdditionalChargesRecords');
        // console.log(transformedAdditionalChargesRecords);

        setAdditionalCharges(transformedAdditionalChargesRecords);

        if (responseData.data.order_contacts) {
          // console.log('responseData.data.order_contacts');
          // console.table(responseData.data.order_contacts);

          const transformedContacts = responseData.data.order_contacts.map((user) => {
            return {
              userUuid: user.userUuid,
              firstName: user.firstName,
              lastName: user.lastName,
              name: user.firstName + ' ' + user.lastName,
              position: user.position,
              phone: user.phone,
              email: user.email,
              notificationPreference: user.notificationPreference,
              status: user.status,
              roleType: user.roleType,
              orgName: user.orgName ? user.orgName : '',
            };
          });

          // console.log('transformedContacts');
          // console.table(transformedContacts);

          setOrderContacts(transformedContacts);
        }

        if (responseData.data.restaurant) {
          // console.log('responseData.data.restaurant');
          // console.table(responseData.data.restaurant);

          const restaurant = responseData.data.restaurant ? responseData.data.restaurant : null;

          const transformedRestaurantData = {
            restaurantUuid: restaurant.restaurant_uuid,
            name: restaurant.name,
            description: restaurant.description,
            address:
              restaurant.address2 === '' || restaurant.address2 === null
                ? restaurant.address1
                : restaurant.address1 + ', ' + restaurant.address2,
            address1: restaurant.address1,
            address2: restaurant.address2,
            city: restaurant.city,
            state: restaurant.state,
            zipcode: restaurant.zipcode,
            email: restaurant.email,
            phone: restaurant.phone,
            foodType: restaurant.food_type,
            status: restaurant.status,
            leagueExperience: restaurant.league_experience,
            deliveryHours: restaurant.delivery_hours,
            deliveryHoursStart: restaurant.delivery_hours_start,
            deliveryHoursEnd: restaurant.delivery_hours_end,
            orderLeadTime: restaurant.order_lead_time,
            taxRate: restaurant.tax_rate,
            rating: restaurant.rating,
            numberOfRatings: restaurant.number_of_ratings,
            uploadsFolder: restaurant.uploadsFolder,
            isParent: restaurant.isParent,
          };

          // console.log('transformedRestaurantData');
          // console.log(transformedRestaurantData);

          // Don't want to do this until the Continue Order button is clicked.
          setSelectedRestaurant(transformedRestaurantData);
        }

        if (responseData.data.delivery_location) {
          const deliveryLocation = responseData.data.delivery_location;

          let deliveryLocationAddressLine1 = deliveryLocation.address1
            ? deliveryLocation.address1
            : '';
          if (deliveryLocation.address2) {
            deliveryLocationAddressLine1 = deliveryLocationAddressLine1.concat(
              ' ',
              deliveryLocation.address2
            );
          }
          let deliveryLocationAddressLine2 =
            deliveryLocation.city + ', ' + deliveryLocation.state + ', ' + deliveryLocation.zipcode;

          const deliveryLocationForDisplay = {
            name: deliveryLocation.name,
            addressLine1: deliveryLocationAddressLine1,
            addressLine2: deliveryLocationAddressLine2,
          };

          setDeliveryLocationForDisplay(deliveryLocationForDisplay);

          // setDeliveryLocation(deliveryLocation);
        }
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        // if (responseData.statusCode === 401) {
        //   history.push(dashboardUrl + 'sessionexpired');
        // }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    authCtx.displayTaxInfo,
    orderUuid,
    FormatDateForDisplay,
    FormatTimeForDisplay,
    FormatLongDateForDisplay,
    FormatLongDateAndTimeForDisplay,
    userRole,
    FormatCurrencyForDisplay,
    dashboardUrl,
  ]);

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <PrintOrderDetails
          deliveryDateAndTimeForDisplay={recordDetails.deliveryDateAndTimeForDisplay}
          deliveryLocationForDisplay={deliveryLocationForDisplay}
          recordDetails={recordDetails}
          orderContacts={orderContacts}
          orderItems={orderItems}
          additionalCharges={additionalCharges}
          selectedRestaurant={selectedRestaurant}
          userRole={userRole}
        />
      ) : null}
    </>
  );
};

export default OrderDetailsPrintout;
