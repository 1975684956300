import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
// import FormControls from '../MUI/FormControls/FormControls';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryLocationAutocompleteFormField from './DeliveryLocationAutocompleteFormField';
import classes from './OrderForm.module.css';

const OrderForm = ({ mode = 'new' }) => {
  // const defaultDate = ''; // new Date().toLocaleDateString('en-CA');
  // const defaultTime = ''; // '13:00';
  // console.log(' - defaultDate = ' + defaultDate + ' - ');
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);
  const history = useHistory();

  const dashboardUrl = authCtx.dashboardUrl;
  const isReorder = orderCtx.isReorder;

  const displayStandardFormFieldsByDefault = true;

  // console.log('mode = ' + mode);

  const buttonText = mode === 'new' ? 'Start Order' : 'Update Order';

  const orderUuid = orderCtx.orderDetails.orderUuid ? orderCtx.orderDetails.orderUuid : '';
  // const currentSelectedDeliveryLocation = orderCtx.deliveryLocation;
  const currentSelectedDeliveryLocation = mode === 'new' ? null : orderCtx.deliveryLocation;
  const currentSelectedDeliveryLocationForDisplay =
    orderCtx.deliveryLocation.name +
    ' (' +
    orderCtx.deliveryLocation.city +
    ', ' +
    orderCtx.deliveryLocation.state +
    ')';
  const currentDeliveryDate = orderCtx.orderDetails.deliveryDate
    ? mode === 'new'
      ? null
      : orderCtx.orderDetails.deliveryDate
    : null;
  const currentDeliveryTime = orderCtx.orderDetails.deliveryTime
    ? mode === 'new'
      ? null
      : orderCtx.orderDetails.deliveryTime
    : null;
  const currentHeadcount = orderCtx.orderDetails.headcount
    ? mode === 'new'
      ? 1
      : orderCtx.orderDetails.headcount
    : 1;

  // console.log('******** OrderForm *********');
  // console.log('orderUuid = ' + orderUuid);
  // console.log('orderCtx.orderDetails');
  // console.log(orderCtx.orderDetails);
  // console.log('currentSelectedDeliveryLocation');
  // console.log(currentSelectedDeliveryLocation);
  // console.log('orderCtx.deliveryLocation');
  // console.log(orderCtx.deliveryLocation);

  // const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [airportDeliveryLocations, setAirportDeliveryLocations] = useState([]);
  const [stadiumDeliveryLocations, setStadiumDeliveryLocations] = useState([]);
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState(
    currentSelectedDeliveryLocation
  );
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  // const [displayMessage, setDisplayMessage] = useState(null);

  // const deliveryLocationRef = useRef();
  const deliveryDateRef = useRef();
  const deliveryTimeRef = useRef();
  const headcountRef = useRef();

  useEffect(() => {
    if (mode === 'new') {
      orderCtx.resetOrder();
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'deliverylocations', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(responseData);
        // console.log('responseData.data.delivery_locations');
        // console.log(responseData.data.delivery_locations);

        const transformedLocations = responseData.data.delivery_locations.map((location) => {
          return {
            deliveryLocationUuid: location.deliveryLocationUuid,
            name: location.name,
            leagues: location.leagues,
            address:
              location.city !== '' && location.state !== ''
                ? location.city + ', ' + location.state
                : location.city !== ''
                ? location.city
                : location.state !== ''
                ? location.state
                : '',
            address1: location.address1,
            address2: location.address2,
            city: location.city,
            state: location.state,
            zipcode: location.zipcode,
            // email: location.email,
            // phone: location.phone,
            // deliveryInstructions: location.deliveryInstructions,
            type: location.type,
            status: location.status,
          };
        });

        // console.log('transformedLocations');
        // console.log(transformedLocations);

        // setDeliveryLocations(transformedLocations);
        // setDisplayedLocations(transformedLocations);

        // setDeliveryLocations(transformedLocations);

        // Using different delilvery location lists.
        setAirportDeliveryLocations(
          transformedLocations.filter((record) => record.type === 'Airport')
        );
        setStadiumDeliveryLocations(
          transformedLocations.filter((record) => record.type === 'Stadium')
        );

        // const filteredRecords = transformedLocations.filter((record) => record.status === 'Active');

        // const filteredDeliveryLocationNames = responseData.data.delivery_locations.map(
        //   ({ name }) => name
        // );

        // console.log('filteredDeliveryLocationNames');
        // console.log(filteredDeliveryLocationNames);

        // console.log('filteredRecords');
        // console.log(filteredRecords);

        // setDeliveryLocations(filteredRecords);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, dashboardUrl, history]);

  const updateOrderDetailsHandler = (orderDetails) => {
    // console.log(' ##### OrderPage - updateOrderDetailsHandler - orderDetails ##### ');
    // console.log(orderDetails);

    // Reset order data first.
    if (currentSelectedDeliveryLocation && selectedDeliveryLocation) {
      let shouldOrderBeReset = false;

      shouldOrderBeReset =
        currentSelectedDeliveryLocation.deliveryLocationUuid !==
        selectedDeliveryLocation.deliveryLocationUuid;

      // I now think that the date or time changing shouldn't reset the order.
      // When a different restaurant is selected then we do.
      // shouldOrderBeReset = shouldOrderBeReset
      //   ? shouldOrderBeReset
      //   : currentDeliveryDate !== orderDetails.deliveryDate;
      // shouldOrderBeReset = shouldOrderBeReset
      //   ? shouldOrderBeReset
      //   : currentDeliveryTime !== orderDetails.deliveryTime;

      shouldOrderBeReset = mode === 'new' ? true : shouldOrderBeReset;

      // console.log('currentDeliveryDate = ' + currentDeliveryDate);
      // console.log('currentDeliveryTime = ' + currentDeliveryTime);
      // console.log('currentSelectedDeliveryLocation');
      // console.log(currentSelectedDeliveryLocation);
      // console.log('selectedDeliveryLocation');
      // console.log(selectedDeliveryLocation);
      // console.log('###### shouldOrderBeReset = ' + shouldOrderBeReset);

      if (shouldOrderBeReset) {
        orderCtx.resetOrder();
        orderCtx.clearOrderItems();
      }
    }

    orderCtx.setOrderDetails(orderDetails);

    // Moved this up with the resetOrder call.
    // Empty the Cart when a new Order is started.
    // orderCtx.clearOrderItems();

    // Testing - trying to get his working again.
    // console.log(
    //   'selectedDeliveryLocation - calling orderCtx.setDeliveryLocation in updateOrderDetailsHandler '
    // );
    // console.log(selectedDeliveryLocation);

    orderCtx.setDeliveryLocation(selectedDeliveryLocation);

    history.replace('/order');
  };

  // Validate Delivery Date is in the Future.
  const checkDeliveryDate = (enteredDeliveryDate, enteredDeliveryTime) => {
    // console.log('##### checkDeliveryDate #####');
    const defaultDeliveryLeadTime = authCtx.defaultOrderLeadTime;
    let orderDeadline = new Date();
    orderDeadline.setHours(orderDeadline.getHours() + defaultDeliveryLeadTime);

    // Testing
    // orderDeadline.setHours(orderDeadline.getHours());

    // I don't think timezone should matter here.
    // Both the Restaurant and Delivery Location should be in the same timezone.
    // const orderDeadlineDate = new Date(
    //   orderDeadline.getTime() - orderDeadline.getTimezoneOffset() * 60000
    // )
    //   .toISOString()
    //   .split('T')[0];

    // I just tried commenting this out along with the 2 console.log calls - I don't think I need this.
    // const orderDeadlineDate = new Date(orderDeadline.getTime()).toISOString().split('T')[0];
    // Simpler version - that I tested with
    // const orderDeadlineDate = orderDeadline.getTime();

    // console.log('defaultDeliveryLeadTime = ' + defaultDeliveryLeadTime);
    // console.log('orderDeadline = ' + orderDeadline);
    // console.log('orderDeadlineDate = ' + orderDeadlineDate);
    // console.log('orderDeadline.getTime() = ' + orderDeadline.getTime());
    // console.log('orderDeadline.getTimezoneOffset() = ' + orderDeadline.getTimezoneOffset());
    // console.log('enteredDeliveryDate = ' + enteredDeliveryDate);
    // console.log('*** orderDeadlineDate = ' + orderDeadlineDate);

    // First version which I thought worked.
    // const dcOrderDeadlineDate = new Date(orderDeadlineDate).getTime();
    // Not sure about the one below ???
    // const dcDeliveryDate = new Date(enteredDeliveryDate).getTime();

    // Simplified version from testing.
    // Not working here...
    // Is working after commenting out some other code.
    const dcOrderDeadlineDate = orderDeadline.getTime();

    // Factor in the enteredDeliveryTime as well.
    const dcDeliveryDate = new Date(enteredDeliveryDate + ' ' + enteredDeliveryTime).getTime();

    // console.log('dcOrderDeadlineDate = ' + dcOrderDeadlineDate);
    // console.log('dcDeliveryDate = ' + dcDeliveryDate);

    if (dcOrderDeadlineDate > dcDeliveryDate) {
      setNotify({
        isOpen: true,
        message:
          'Orders must be be placed at least ' +
          defaultDeliveryLeadTime +
          ' hours before delivery.  Please enter/select a Delivery Date and Time accordingly.',
        type: 'warning',
      });

      return false;
    }
    // console.log('Date is OK - Order Started');

    return true;
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setIsError(false);

    // console.log('submitHandler');
    // console.log('selectedDeliveryLocation');
    // console.log(selectedDeliveryLocation);

    // const selectedDeliveryLocationName = deliveryLocationRef.current.value;

    const enteredDeliveryDate = deliveryDateRef.current.value;
    const enteredDeliveryTime = deliveryTimeRef.current.value;
    const enteredHeadcount = headcountRef.current.value;

    if (enteredDeliveryDate) {
      if (!checkDeliveryDate(enteredDeliveryDate, enteredDeliveryTime)) {
        console.log('checkDeliveryDate - NOT GOOD');
        return;
      }
    }

    if (Object.is(selectedDeliveryLocation, null)) {
      // console.log('### NO selectedDeliveryLocation ###');
      setNotify({
        isOpen: true,
        message: 'You must select a Delivery Location when starting a New Order.',
        type: 'error',
      });
      return;
      // } else {
      //   console.log('### selectedDeliveryLocation ###');
    }

    if (!enteredDeliveryDate) {
      setNotify({
        isOpen: true,
        message: 'You must select a Delivery Date when starting a New Order.',
        type: 'error',
      });
      return;
    }

    if (!enteredDeliveryTime) {
      setNotify({
        isOpen: true,
        message: 'You must select a Delivery Time when starting a New Order.',
        type: 'error',
      });
      return;
    }

    // Validation done.
    setIsLoading(true);

    // if (!enteredDeliveryCity) {
    //   console.log('You must enter a Delivery Address (city).');
    // }

    // const selectedDeliveryLocation = deliveryLocations.filter(
    //   (record) => record.name === selectedDeliveryLocationName
    // );

    // console.log('selectedDeliveryLocation - calling orderCtx.setDeliveryLocation ');
    // console.log(selectedDeliveryLocation);

    // orderCtx.setDeliveryLocation(selectedDeliveryLocation);

    let selectedDeliveryLocationUuid = '';
    // let selectedDeliveryState = '';
    // let selectedDeliveryZipcode = '';
    if (selectedDeliveryLocation) {
      selectedDeliveryLocationUuid = selectedDeliveryLocation.deliveryLocationUuid;
      // enteredDeliveryCity = selectedDeliveryLocation.city;
      // selectedDeliveryState = selectedDeliveryLocation.state;
      // selectedDeliveryZipcode = selectedDeliveryLocation.zipcode;
    }

    // console.log('selectedDeliveryLocationUuid = ' + selectedDeliveryLocationUuid);
    // console.log('enteredDeliveryCity = ' + enteredDeliveryCity);
    // console.log('selectedDeliveryState = ' + selectedDeliveryState);
    // console.log('selectedDeliveryZipcode = ' + selectedDeliveryZipcode);
    // console.log('enteredDeliveryDate = ' + enteredDeliveryDate);
    // console.log('enteredDeliveryTime = ' + enteredDeliveryTime);
    // console.log('enteredHeadcount = ' + enteredHeadcount);

    let apiURL = '';
    let apiMethod = '';

    if (orderUuid !== '' && orderUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'orders';
      apiMethod = 'POST';
    }

    // apiURL = authCtx.baseApiUrl + 'orders';
    // apiMethod = 'POST';

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);
    // console.log(' - enteredStatus = ' + enteredStatus);

    // I don't this should be here.  It is called later
    // orderCtx.resetOrder();

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        delivery_location_uuid: selectedDeliveryLocationUuid,
        // delivery_city: enteredDeliveryCity,
        // delivery_state: selectedDeliveryState,
        // delivery_zipcode: selectedDeliveryZipcode,
        delivery_date: enteredDeliveryDate,
        delivery_time: enteredDeliveryTime,
        headcount: enteredHeadcount,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        if (deliveryDateRef.current) {
          deliveryDateRef.current.value = '';
        }
        if (deliveryTimeRef.current) {
          deliveryTimeRef.current.value = '';
        }
        if (headcountRef.current) {
          headcountRef.current.value = 1;
        }

        return res.json().then((responseData) => {
          // console.log(' ** OrderForm - SUCCESS ** ');
          // console.log(responseData);
          updateOrderDetailsHandler(responseData.data.orders[0]);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          setNotify({
            isOpen: true,
            message: 'An Error occurred while saving Order Information.  Please try again.',
            type: 'error',
          });
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
          } else {
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  const setDeliveryLocationHandler = (event, value) => {
    // console.log(' -- setDeliveryLocationHandler -- ');
    // console.log('value');
    // console.log(value);
    setSelectedDeliveryLocation(value);
  };

  const selectDeliveryTypeHandler = (type) => {
    // console.log('### selectDeliveryTypeHandler - type = ' + type + ' ###');

    setSelectedDeliveryType(type);
  };

  const clearDeliveryTypeHandler = () => {
    setSelectedDeliveryType('');
  };

  const displayStandardFormFields = () => {
    return (
      <>
        <Box>
          <Typography variant='formLabel' component='label'>
            Delivery Date
          </Typography>
          <TextField
            type='date'
            id='delivery_date'
            size='small'
            inputRef={deliveryDateRef}
            defaultValue={currentDeliveryDate}
          />
        </Box>
        <Box>
          <Typography variant='formLabel' component='label'>
            Delivery Time
          </Typography>
          <TextField
            type='time'
            id='delivery_time'
            size='small'
            inputRef={deliveryTimeRef}
            defaultValue={currentDeliveryTime}
          />
        </Box>
        <Box>
          <Typography variant='formLabel' component='label'>
            Headcount
          </Typography>
          <TextField
            type='number'
            min='1'
            max='10000'
            step='1'
            id='headcount'
            size='small'
            defaultValue={currentHeadcount}
            sx={{ width: '8rem' }}
            inputRef={headcountRef}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {/* onSubmit={submitHandler} */}

      {/* Testing */}
      {/* <Box>selectedDeliveryType = {selectedDeliveryType}</Box> */}

      {!isLoading && !isError ? (
        <Paper elevation={4} sx={{ maxWidth: '120rem', width: '100%', p: '10px', m: '0 auto' }}>
          <Box component='form' className={classes['form']}>
            <Box className={classes['form-fields']}>
              {isReorder ? (
                <Box>
                  <Typography variant='formLabel' component='label'>
                    Delivery Location
                  </Typography>
                  <TextField
                    disabled
                    id='delivery_location_disabled'
                    size='small'
                    defaultValue={currentSelectedDeliveryLocationForDisplay}
                    sx={{ width: '50rem' }}
                  />
                </Box>
              ) : (
                <>
                  {selectedDeliveryType ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        {selectedDeliveryType === 'Airport' ? (
                          <DeliveryLocationAutocompleteFormField
                            currentDeliveryLocation={currentSelectedDeliveryLocation}
                            deliveryLocations={airportDeliveryLocations}
                            onChange={setDeliveryLocationHandler}
                            type={selectedDeliveryType}
                            onResetDeliveryType={selectDeliveryTypeHandler}
                            label='Airport Delivery Locations'
                            labelClearDeliveryType='Reset Delivery Type'
                            onClearDeliveryType={clearDeliveryTypeHandler}
                          />
                        ) : (
                          <DeliveryLocationAutocompleteFormField
                            currentDeliveryLocation={currentSelectedDeliveryLocation}
                            deliveryLocations={stadiumDeliveryLocations}
                            onChange={setDeliveryLocationHandler}
                            type={selectedDeliveryType}
                            onResetDeliveryType={selectDeliveryTypeHandler}
                            label='Stadium Delivery Locations'
                            labelClearDeliveryType='Reset Delivery Type'
                            onClearDeliveryType={clearDeliveryTypeHandler}
                          />
                        )}
                      </Box>

                      {!displayStandardFormFieldsByDefault ? (
                        <>{displayStandardFormFields()}</>
                      ) : null}
                    </>
                  ) : (
                    <Box>
                      <Typography variant='formLabel' component='label'>
                        Select Delivery Type
                      </Typography>
                      <Box className='flex-container-side-by-side-tiny-gap'>
                        <button
                          onClick={() => selectDeliveryTypeHandler('Airport')}
                          type='button'
                          className='small'
                        >
                          Airport
                        </button>
                        <button
                          onClick={() => selectDeliveryTypeHandler('Stadium')}
                          type='button'
                          className='small'
                        >
                          Stadium
                        </button>
                      </Box>
                    </Box>
                  )}

                  {displayStandardFormFieldsByDefault ? <>{displayStandardFormFields()}</> : null}
                </>
              )}
            </Box>

            <Box>
              <Button
                variant='contained'
                color='accent'
                type='submit'
                onClick={submitHandler}
                sx={{ mt: '30px' }}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>

          <Notification notify={notify} setNotify={setNotify} />
        </Paper>
      ) : null}
    </>
  );
};

export default OrderForm;
