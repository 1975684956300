import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import MenuItemWithOptionsCard from './MenuItemWithOptionsCard';

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const MenuItemList = () => {
  // console.log('***** MenuItemList ******');

  const authCtx = useContext(AuthContext);

  const statusFilterRef = useRef();
  const menuItemCategoryFilterRef = useRef();

  // const restaurantName = authCtx.selectedRestaurantName;
  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;
  const defaultMenuItemImage = authCtx.defaultMenuItemImage;

  const displayDeleteMenuButton = false;

  const [pageTitle, setPageTitle] = useState('Menu: ');
  const [menuItems, setMenuItems] = useState([]);
  const [displayedMenuItems, setDisplayedMenuItems] = useState([]);
  const [menuItemCategories, setMenuItemCategories] = useState([]);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();
  const params = useParams();
  let restaurantUuid = params.restaurantUuid;
  if (!restaurantUuid) {
    restaurantUuid = authCtx.selectedRestaurantUuid;
  }
  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + restaurantUuid + '/';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'menuitems/restaurant/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data.restaurant_details)');
        // console.log(responseData.data.restaurant_details);
        // console.log('responseData.data.menu_items)');
        // console.log(responseData.data.menu_items);

        const restaurantName = responseData.data.restaurant_details
          ? responseData.data.restaurant_details.name
          : '';
        setPageTitle('Menu: ' + restaurantName);

        const returnedMenuItems = responseData.data.menu_items.map((menuItem) => {
          return {
            menuItemUuid: menuItem.menuItemUuid,
            parentUuid: menuItem.parentUuid,
            name: menuItem.name,
            description: menuItem.description,
            details: menuItem.details,
            servingSize: menuItem.servingSize ? menuItem.servingSize : 'Each',
            feeds: menuItem.feeds,
            price: menuItem.price,
            priceFormatted: menuItem.price ? priceFormatter.format(menuItem.price) : 0.0,
            priceDetails: menuItem.priceDetails,
            menuItemCategoryId: menuItem.menuItemCategoryId,
            menuItemCategory: menuItem.menuItemCategory,
            dietaryOptions: menuItem.dietaryOptions,
            status: menuItem.status,
            image: menuItem.image,
            imageUrl: menuItem.image
              ? baseApiUploadedImagesPath + menuItem.image
              : Constants.IMAGE_FOLDER_PATH + defaultMenuItemImage,
          };
        });

        let parentMenuItems = returnedMenuItems.filter(
          (menuItem) => menuItem.parentUuid === null || menuItem.parentUuid === ''
        );
        // console.log('parentMenuItems');
        // console.table(parentMenuItems);

        const parentAndChildMenuItems = [...parentMenuItems];

        const childMenuItems = returnedMenuItems.filter(
          (menuItem) => menuItem.parentUuid !== null && menuItem.parentUuid !== ''
        );
        // console.log('childMenuItems');
        // console.log(childMenuItems);

        // Sort childMenuItems by feeds so that they are displayed in the right order.
        const sortedChildMenuItems = childMenuItems.sort(({ feeds: a }, { feeds: b }) => b - a);

        const getChildMenuItems = (parentUuid) => {
          // console.log('getChildMenuItems - parentUuid = ' + parentUuid);

          let childItems = [];
          if (childMenuItems) {
            childItems = childMenuItems.filter((menuItem) => menuItem.parentUuid === parentUuid);
          }

          return childItems;
        };

        const createMenuItemsWithOptionsArray = () => {
          let menuItems = [];

          for (let index in parentMenuItems) {
            let menuItem = parentMenuItems[index];
            let menuItemUuid = menuItem.menuItemUuid;

            // menuItem['options'] = 'All Options would go here';

            // console.log('menuItem');
            // console.log(menuItem);
            // console.log('menuItemUuid = ' + menuItemUuid);

            let childMenuItems = getChildMenuItems(menuItemUuid);

            // console.log('childMenuItems');
            // console.log(childMenuItems);

            menuItem['options'] = childMenuItems;

            // console.log('menuItem');
            // console.log(menuItem);

            menuItems.push(menuItem);
          }

          // console.log('menuItems');
          // console.log(menuItems);

          setMenuItems(menuItems);
          const filteredMenuItems = menuItems.filter((menuItem) => menuItem.status === 'Active');
          setDisplayedMenuItems(filteredMenuItems);
        };

        if (childMenuItems) {
          createMenuItemsWithOptionsArray();
        }

        // Add the child items to the parentMenuItems array.
        for (let index in sortedChildMenuItems) {
          // console.log('sortedChildMenuItems[index]');
          // console.table(sortedChildMenuItems[index]);

          let childMenuItem = sortedChildMenuItems[index];
          let parentUuid = childMenuItem.parentUuid;
          let parentIndex = parentAndChildMenuItems.findIndex((object) => {
            return object.menuItemUuid === parentUuid;
          });

          // console.log('parentUuid = ' + parentUuid);
          // console.log('parentIndex = ' + parentIndex);
          // console.log('childMenuItem.feeds = ' + childMenuItem.feeds);

          parentAndChildMenuItems.splice(parentIndex + 1, 0, childMenuItem);
        }
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    baseApiUploadedImagesPath,
    defaultMenuItemImage,
    dashboardUrl,
    history,
  ]);

  useEffect(() => {
    const fetchDataGlobalMenuItemCategories = async () => {
      // console.log(' ** fetchDataGlobalMenuItemCategories - useEffect - menuitemcategories ** ');
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/menuitemcategories', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData);

        const justMenuItemCategoryNames = responseData.data.active_menu_item_categories.map(
          (category) => {
            return `${category.name}`;
          }
        );

        // setMenuItemCategories(responseData.data.active_menu_item_categories);
        setMenuItemCategories(justMenuItemCategoryNames);
      } else {
        setIsError(true);
      }
      // setIsLoading(false);
    };

    fetchDataGlobalMenuItemCategories().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  // const deleteMenuItemHandler = (event) => {
  //   const menuItemUuid = event.target.getAttribute('data-menu-item-uuid');
  //   console.log(' -- deleteMenuItemHandler -- ');
  //   console.log('menuItemUuid = ' + menuItemUuid);

  //   const deleteData = async () => {
  //     const response = await fetch(authCtx.baseApiUrl + 'menuitems/' + menuItemUuid, {
  //       method: 'DELETE',
  //       headers: {
  //         Authorization: authCtx.token,
  //       },
  //     });

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log(responseData);
  //     } else {
  //       console.log(' -- setIsError(true) -- ');
  //       setIsError(true);
  //     }
  //     setIsLoading(false);
  //   };

  //   deleteData().catch(console.error);
  // };

  const addNewHandler = () => {
    history.replace(dashboardUrl + 'restaurants/menu-item/add');
  };

  const clearFilterHandler = () => {
    menuItemCategoryFilterRef.current.value = 'Show All';
    if (statusFilterRef.current) {
      statusFilterRef.current.value = 'Active';
    }

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let menuItemCategoryFilter = menuItemCategoryFilterRef.current.value;
    let statusFilter = 'Active';
    if (statusFilterRef.current) {
      statusFilter = statusFilterRef.current.value;
    }

    // console.log(' -- filterDisplayedRecords -- ');
    // console.log({ statusFilter: statusFilter });
    // console.log({ menuItemCategoryFilter: menuItemCategoryFilter });

    if (menuItemCategoryFilter === 'Show All' && statusFilter === 'Show All') {
      setDisplayedMenuItems(menuItems);
    } else {
      let filteredRecords = menuItems;

      if (menuItemCategoryFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter(
          (record) => record.menuItemCategory === menuItemCategoryFilter
        );
      }
      if (statusFilter !== 'Show All') {
        filteredRecords = filteredRecords.filter((record) => record.status === statusFilter);
      }

      setDisplayedMenuItems(filteredRecords);
    }
  };

  const displayDetailsHandler = (menuItemUuid) => {
    // console.log('displayDetailsHandler');
    // console.log('menuItemUuid = ' + menuItemUuid);
    history.replace(`${dashboardUrl}restaurants/menu-items/details/${menuItemUuid}`);
  };

  const displayFormHandler = (menuItemUuid) => {
    // console.log('displayFormHandler');
    // console.log('menuItemUuid = ' + menuItemUuid);
    // console.log('dashboardUrl = ' + dashboardUrl);
    history.replace(`${dashboardUrl}restaurants/menu-item/edit/${menuItemUuid}`);
  };

  const displayMenuItemCards = (displayedMenuItems) => {
    return displayedMenuItems.map((menuItem, index) => {
      return (
        <MenuItemWithOptionsCard
          menuItem={menuItem}
          userRole={userRole}
          onDisplayDetails={displayDetailsHandler}
          onDisplayForm={displayFormHandler}
          key={index}
        />
      );
    });
  };

  const displayRestaurantDetailsHandler = () => {
    history.replace(`${dashboardUrl}restaurants/details/${restaurantUuid}`);
  };

  const backToListHandler = () => {
    history.replace(`${dashboardUrl}restaurants`);
  };

  const deleteMenuHandler = () => {
    // console.log('### deleteMenuHandler ###');

    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure you want to delete the Menu for ' + authCtx.selectedRestaurantName + '.',
      subTitle: 'If any Orders contain any of the Menu Items, they will be "SOFT" deleted.',
      onConfirm: () => {
        deleteRestaurantsMenu(restaurantUuid);
      },
    });
  };

  const deleteRestaurantsMenu = (restaurantUuid) => {
    // console.log('### deleteRestaurantsMenu - restaurantUuid = ' + restaurantUuid + ' ###');

    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteMenuData(restaurantUuid);
    setNotify({ isOpen: true, message: 'Restaurants Menu Successfully Deleted', type: 'error' });
  };

  const deleteMenuData = async (restaurantUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'menuitems/restaurant/' + restaurantUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log('##### responseData #####');
      // console.log(responseData);
      await response.json();

      // Reset the data so that the component will reload.
      setMenuItems([]);
      setDisplayedMenuItems([]);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <StandardPageWrapper>
      {!isLoading && !isError ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {pageTitle}
            {userRole === 'Vendor' ? (
              <Typography component='div' sx={{ pt: '10px' }}>
                Do you need to make a change to your Menu? Let us know and we will take care of it.
              </Typography>
            ) : null}
          </Typography>

          <Box className='button-holder-no-pb'>
            {userRole === 'Admin' ? (
              <Button variant='contained' size='custom' color='accent' onClick={addNewHandler}>
                Add New Menu Item
              </Button>
            ) : null}
            {userRole !== 'Vendor' ? (
              <>
                <Button
                  variant='contained'
                  size='custom'
                  color='accent'
                  onClick={displayRestaurantDetailsHandler}
                >
                  Details
                </Button>
                <Button
                  variant='contained'
                  size='custom'
                  color='accent'
                  onClick={backToListHandler}
                >
                  Restaurants
                </Button>
              </>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {!isLoading && !isError ? (
        <Box className='flex-between-container'>
          <Box className='button-holder'>
            <Box>
              <Box>
                <label htmlFor='filter-by-menu-item-category'>Menu Item Category</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-menu-item-category',
                menuItemCategoryFilterRef,
                filterDisplayedRecords,
                menuItemCategories
              )}
            </Box>
            {userRole !== 'User' ? (
              <Box>
                <Box>
                  <label htmlFor='filter-by-status'>Status</label>
                </Box>
                {displayListFilterSelect(
                  'filter-by-status',
                  statusFilterRef,
                  filterDisplayedRecords,
                  Constants.STATUS_ARRAY,
                  'Active'
                )}
              </Box>
            ) : null}

            <Box className='align-bottom'>
              <button className='small' onClick={clearFilterHandler}>
                Clear Filters
              </button>
            </Box>
          </Box>

          {displayDeleteMenuButton && userRole === 'Admin' ? (
            <Box className='align-bottom pb-20'>
              <button className='red' onClick={deleteMenuHandler}>
                Delete Menu
              </button>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && menuItems.length === 0 ? (
        <WorkflowMessage type='nodata' message='No Menu Items Found...' />
      ) : null}

      {!isLoading && !isError && menuItems.length > 0 ? (
        <Box className='flex-side-by-side-stretch'>{displayMenuItemCards(displayedMenuItems)}</Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </StandardPageWrapper>
  );
};

export default MenuItemList;
