import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import FormControls from '../MUI/FormControls/FormControls';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import RestaurantAutocompleteFormField from '../Restaurant/RestaurantAutocompleteFormField';
// import classes from '../CSS/standard-form.module.css';
import classes from '../CSS/standard-mui-form.module.css';

const CopyMenuForm = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const dashboardUrl = authCtx.dashboardUrl;

  const [restaurants, setRestaurants] = useState([]);
  const [targetRestaurants, setTargetRestaurants] = useState([]);

  const [sourceRestaurant, setSourceRestaurant] = useState([]);
  const [targetRestaurant, setTargetRestaurant] = useState([]);

  const [copyMenuButtonEnabled, setCopyMenuButtonEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    setIsLoading(true);
    const apiUrl = authCtx.baseApiUrl + 'restaurants';

    const fetchData = async () => {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data);

        const transformedRestaurants = responseData.data.restaurants
          .filter((restaurant) => restaurant.status === 'Active')
          .map((restaurant) => {
            return {
              restaurantUuid: restaurant.restaurantUuid,
              name: restaurant.name,
              status: restaurant.status,
              isParent: restaurant.isParent,
            };
          });

        setRestaurants(transformedRestaurants);

        // console.log('transformedRestaurants', transformedRestaurants);

        setTargetRestaurants(transformedRestaurants);

        // const filteredRestaurants = transformedRestaurants.filter(
        //   (restaurant) => restaurant.status === 'Active'
        // );

        // const transformedRestaurants = responseData.data.restaurants.map((restaurant) => {
        //   return {
        //     restaurantUuid: restaurant.restaurantUuid,
        //     name: restaurant.name,
        //     status: restaurant.status,
        //   };
        // });

        // // setRestaurants(transformedRestaurants);

        // const filteredRestaurants = transformedRestaurants.filter(
        //   (restaurant) => restaurant.status === 'Active'
        // );

        // setRestaurants(filteredRestaurants);

        // console.log('filteredRestaurants', filteredRestaurants);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  const setSourceRestaurantHandler = (event, value) => {
    // console.log(' -- setSourceRestaurantHandler -- ');
    // console.log('value');
    // console.log(value);

    setSourceRestaurant(value);

    if (!value) {
      setCopyMenuButtonEnabled(false);
      return;
    }

    // console.log('restaurant.restaurantUuid =  ' + sourceRestaurant.restaurantUuid);
    // console.log('value.restaurantUuid =  ' + value.restaurantUuid);

    const filteredRestaurants = restaurants.filter(
      (restaurant) => restaurant.restaurantUuid !== value.restaurantUuid
    );

    // console.log('filteredRestaurants');
    // console.log(filteredRestaurants);

    setTargetRestaurants(filteredRestaurants);

    setCopyMenuButtonEnabled('name' in value && 'name' in targetRestaurant);
  };

  const setTargetRestaurantHandler = (event, value) => {
    // console.log(' -- setTargetRestaurantHandler -- ');
    // console.log('value');
    // console.log(value);

    setTargetRestaurant(value);

    if (!value) {
      setCopyMenuButtonEnabled(false);
      return;
    }

    setCopyMenuButtonEnabled('name' in value && 'name' in sourceRestaurant);
  };

  const copyMenuHandler = () => {
    // console.log('### copyMenuHandler ###');

    setConfirmDialog({ ...confirmDialog, isOpen: false });

    submitHandler();
  };

  // const submitHandler = (event) => {
  const submitHandler = () => {
    // console.log('### submitHandler ###');

    // event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const makeCall = true;

    const apiURL = authCtx.baseApiUrl + 'copymenu';
    const apiMethod = 'POST';

    // console.log('makeCall = ' + makeCall);
    // console.log('apiURL', apiURL);
    // console.log('apiMethod', apiMethod);
    // console.log('sourceRestaurant', sourceRestaurant.restaurantUuid);
    // console.log('targetRestaurant', targetRestaurant.restaurantUuid);

    if (makeCall) {
      fetch(apiURL, {
        method: apiMethod,
        body: JSON.stringify({
          source_restaurant_uuid: sourceRestaurant.restaurantUuid,
          target_restaurant_uuid: targetRestaurant.restaurantUuid,
        }),
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.ok) {
          setNotify({
            isOpen: true,
            message: 'Restaurant Menu Successfully Copied.',
            type: 'success',
          });

          return res.json().then((responseData) => {
            // console.log(' ** SUCCESS ** ');
            // console.log(responseData);

            history.replace(
              dashboardUrl + 'restaurants/menu-items/' + targetRestaurant.restaurantUuid
            );
          });
        } else {
          return res.json().then((responseData) => {
            if (
              responseData &&
              Array.isArray(responseData.messages) &&
              responseData.messages.length
            ) {
              const messageText = responseData.messages.map((message) => `${message}`).join(', ');
              setNotify({ isOpen: true, message: messageText, type: 'error' });
            } else {
              setNotify({
                isOpen: true,
                message: 'Restaurant Menu Not Copied',
                type: 'error',
              });
            }
          });
        }
      });
    }
  };

  return (
    <StandardPageWrapper>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {!isLoading && !isError && restaurants.length === 0 ? (
        <WorkflowMessage type='error' message='No Restaurants Found...' />
      ) : null}

      <Typography variant='h1' component='h1'>
        Copy Menu
      </Typography>

      <Paper elevation={4} className={classes['form-container']}>
        <Box>
          <Typography>
            To copy one Restaurant's over to another Restaurant, select a Source and Target
            Restaurant from the dropdowns and then click on the COPY MENU button.
          </Typography>
          <Typography>
            Keep in mind that all of the Menu Items from the Source Restaurant will be copied. Once
            copied each Restaurant will have their own version of the Menu Items so any edits will
            only be done for the currently selected Restaurant.
          </Typography>
        </Box>
        <form onSubmit={submitHandler}>
          <Box className='flex-container-side-by-side'>
            <RestaurantAutocompleteFormField
              restaurants={restaurants}
              label='Source Restaurant'
              formFieldId='sourceRestaurantUuid'
              onChange={setSourceRestaurantHandler}
            />

            <RestaurantAutocompleteFormField
              restaurants={targetRestaurants}
              label='Target Restaurant'
              formFieldId='targetRestaurantUuid'
              onChange={setTargetRestaurantHandler}
            />
          </Box>

          <Box component='div' className={classes['actions-container']} sx={{ pt: '20px' }}>
            <FormControls.ButtonControl
              text='Copy Menu'
              type='button'
              disabled={!copyMenuButtonEnabled}
              color='primary'
              // onClick={copyMenuHandler}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title:
                    'Are you sure you want to copy ' +
                    sourceRestaurant.name +
                    ' menu over to ' +
                    targetRestaurant.name +
                    '.',
                  subTitle: 'All menu items from ' + sourceRestaurant.name + ' will be copied.',
                  onConfirm: () => {
                    copyMenuHandler();
                  },
                });
              }}
            />
          </Box>
        </form>
      </Paper>

      <Box className='mt-20'>
        <Typography className='small-text'>* Parent (Main) Restaurant</Typography>
      </Box>

      {/* <Box className='pt-20'>
        <Typography>{sourceRestaurant.name}</Typography>
        <Typography>{targetRestaurant.name}</Typography>
        <Typography>{copyMenuButtonEnabled ? 'Button Enabled' : 'Button Disabled'}</Typography>
      </Box> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </StandardPageWrapper>
  );
};

export default CopyMenuForm;
