import { createTheme } from '@mui/material';

const EECustomTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'hsl(205, 76%, 41%)',
      light: 'hsl(205, 76%, 61%)',
      dark: 'hsl(205, 76%, 21%)',
    },
    secondary: {
      main: 'hsl(186, 93%, 40%)',
      light: 'hsl(186, 93%, 60%)',
      dark: 'hsl(186, 93%, 20%)',
    },
    buttons1: {
      main: 'hsl(0, 0%, 50%)',
      light: 'hsl(0, 0%, 70%)',
      dark: 'hsl(0, 0%, 40%)',
    },
    primaryDark: {
      main: 'hsl(205, 76%, 20%)',
      light: 'hsl(205, 76%, 30%)',
      dark: 'hsl(205, 76%, 10%)',
    },
    // accent: {
    //   main: 'hsl(77, 67%, 45%)',
    //   light: 'hsl(77, 67%, 69%)',
    //   dark: 'hsl(77, 67%, 25%)',
    // },
    accent: {
      main: '#75A825',
      // light: '#75A825',
      light: '#96d72f',
      // dark: '#75A825',
      dark: '#508501',
      contrastText: '#ffffff',
    },
    neutral100: {
      main: 'hsl(0, 0%, 90%)',
      light: 'hsl(0, 0%, 100%)',
      dark: 'hsl(0, 0%, 80%)',
    },
    neutral200: {
      main: 'hsl(0, 0%, 80%)',
      light: 'hsl(0, 0%, 90%)',
      dark: 'hsl(0, 0%, 60%)',
    },
    neutral500: {
      main: 'hsl(0, 0%, 50%)',
      light: 'hsl(0, 0%, 70%)',
      dark: 'hsl(0, 0%, 40%)',
    },
    neutral1000: {
      main: 'hsl(0, 0%, 20%)',
      light: 'hsl(0, 0%, 40%)',
      dark: 'hsl(100, 0%, 0%)',
    },
    background: {
      default: '#eeeeee',
    },
    highlight: {
      default: '#ffa500',
      main: '#ffa500',
    },
  },
  typography: {
    htmlFontSize: 10,
    // fontFamily: ['Playfair Display', 'serif'].join(','),
    // fontFamily: ['Jost', 'sans-serif'].join(','),
    fontFamily: ['Helvetica', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.4rem',
      fontWeight: 'bold',
      paddingBottom: '1.4rem',
      textAlign: 'left',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      paddingBottom: '0.2rem',
    },
    h4: {
      fontSize: '2.5rem',
      paddingBottom: '1rem',
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: 'hsl(205, 76%, 41%)',
    },
    h6: {
      paddingBottom: '1.8rem',
      display: 'block',
    },
    h7: {
      fontSize: '1.6rem',
      color: 'hsl(205, 76%, 41%)',
      fontWeight: 'bold',
      display: 'block',
      paddingBottom: '0.8rem',
    },
    h8: {
      fontSize: '1.5rem',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(205, 76%, 41%)',
      paddingBottom: '0.8rem',
    },
    h9: {
      fontSize: '1.5rem',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(205, 76%, 41%)',
      paddingBottom: '0.2rem',
    },
    formLabel: {
      fontSize: '1.4rem',
      // display: 'inline-block',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
      paddingBottom: '0.5rem',
    },
    labelPrimary: {
      fontSize: '1.6rem',
      display: 'inline-block',
      fontWeight: 'bold',
      color: 'hsl(205, 76%, 41%)',
    },
    label: {
      fontSize: '1.6rem',
      display: 'block',
      // display: 'block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
    },
    labelAbove: {
      fontSize: '1.6rem',
      display: 'inline-block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
      paddingBottom: '1rem',
    },
    labelInline: {
      fontSize: '1.6rem',
      display: 'inline-block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
      paddingRight: '1rem',
    },
    labelInlineRightAligned: {
      fontSize: '1.6rem',
      display: 'inline-block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
      paddingRight: '1rem',
      textAlign: 'right',
      // minWidth: '100px',
    },
    labelSmall: {
      fontSize: '1.2rem',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
    },
    labelLarge: {
      fontSize: '2rem',
      display: 'inline-block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
      lineHeight: '2',
    },
    bold: {
      fontSize: '1.6rem',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(0, 0%, 0%)',
    },
    boldWhite: {
      fontSize: '1.4rem',
      display: 'block',
      fontWeight: 'bold',
      color: '#fff',
    },
    boldPrimary: {
      fontSize: '1.6rem',
      display: 'block',
      fontWeight: 'bold',
      color: 'hsl(205, 76%, 41%)',
    },
    bodyContent: {
      fontSize: '1.6rem',
      // display: 'block',
      fontWeight: 'normal',
      color: 'hsl(0, 0%, 0%)',
      paddingBottom: '1rem',
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2',
    },
    bodyContentNoPadding: {
      fontSize: '1.6rem',
      // display: 'block',
      fontWeight: 'normal',
      color: 'hsl(0, 0%, 0%)',
      paddingBottom: '0',
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2',
    },
    bodyContentSmall: {
      fontSize: '1.2rem',
      // display: 'block',
      fontWeight: 'normal',
      color: 'hsl(0, 0%, 0%)',
      paddingBottom: '1rem',
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2',
    },
    // formLabel: {
    //   fontSize: '1.4rem',
    //   display: 'block',
    //   fontWeight: 'bold',
    //   // color: 'hsl(0, 0%, 0%)',
    //   color: 'rgba(0,0,0,0.6)',
    //   paddingBottom: '0.2rem',
    //   // whiteSpace: 'pre-wrap',
    //   // lineHeight: '1.2',
    // },
    noDataMessage: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: 'hsl(0, 65%, 51%)',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '3rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          transform: 'translateZ(3)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeCustom: {
          minWidth: '150px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
          paddingTop: '5px',
        },
      },
    },
  },
});

export default EECustomTheme;
