import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaidIcon from '@mui/icons-material/Paid';
import PrintIcon from '@mui/icons-material/Print';

import {
  buildVersion,
  paymentNotConfirmedStatusValue,
  orderLeadTimeExceededStatusValue,
} from '../../constants/global';

import AuthContext from '../../store/auth-context';
import { useTable } from '../Hooks/useTable';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import WorkflowMessage from '../UI/WorkflowMessage';
import Popup from '../MUI/UI/Popup';
import FormControls from '../MUI/FormControls/FormControls';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import OrdersListMenu from './OrdersListMenu';
import OrderDetailsPrintout from './OrderDetailsPrintout';

const OrdersList = () => {
  // console.log(' ######## OrdersList ######## ');
  const authCtx = useContext(AuthContext);

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const ordersStatusFilter = authCtx.ordersStatusFilter;
  // console.log('ordersStatusFilter = ' + ordersStatusFilter);

  const { FormatTimeForDisplay } = useFormatDateAndTime();

  let restaurantUuid = '';
  if (userRole === 'Vendor') {
    restaurantUuid = authCtx.selectedRestaurantUuid
      ? authCtx.selectedRestaurantUuid
      : authCtx.orgUuid;
  }

  let defaultFilterByStatus = 'New';
  if (ordersStatusFilter !== '') {
    defaultFilterByStatus = ordersStatusFilter;
  } else {
    if (userRole === 'User') {
      defaultFilterByStatus = 'Placed';
    }
    if (userRole === 'Admin') {
      defaultFilterByStatus = 'Placed';
    }
  }

  const [records, setRecords] = useState([]);
  const [displayedRecords, setDisplayedRecords] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState(defaultFilterByStatus);
  const [openPopupPrintOrderDetails, setOpenPopupPrintOrderDetails] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [filterFunction, setFilterFunction] = useState({
    function: (records) => {
      return records;
    },
  });

  const [selectedOrderUuid, setSelectedOrderUuid] = useState('');
  const [selectedOrderNumber, setSelectedOrderNumber] = useState('');

  const searchRef = useRef();

  const history = useHistory();

  const refreshApp = useCallback(() => {
    // console.log('##### refreshApp #####');
    // console.log('before - versionUpdatedRefreshNeeded', authCtx.versionUpdatedRefreshNeeded);
    authCtx.setVersionUpdatedRefreshNeeded(true);
    // console.log('after - versionUpdatedRefreshNeeded', authCtx.versionUpdatedRefreshNeeded);
  }, [authCtx]);

  const getAllOrderRecords = useCallback(async () => {
    // console.log(' ##### getAllOrderRecords ##### ');
    // console.log('### restaurantUuid = ' + restaurantUuid + ' ###');

    setIsLoading(true);
    setIsError(false);

    let apiUrl = authCtx.baseApiUrl + 'orders';
    if (restaurantUuid) {
      apiUrl = authCtx.baseApiUrl + 'orders/restaurant/' + restaurantUuid;
    }

    // console.log('apiUrl = ' + apiUrl);

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      // console.log(responseData);
      // console.log(responseData.data.orders);

      const apiBuildVersion = responseData.buildVersion;
      const uiBuildVersion = buildVersion;

      console.log(
        'apiBuildVersion = ' + apiBuildVersion + ' - ' + uiBuildVersion + ' = uiBuildVersion'
      );

      if (apiBuildVersion !== uiBuildVersion) {
        refreshApp();
        // console.log('uiBuildVersion !== apiBuildVersion');
        // } else {
        //   console.log('NOT ##### uiBuildVersion !== apiBuildVersion');
      }

      const transformedRecords = responseData.data.orders.map((order) => {
        return {
          orderUuid: order.orderUuid,
          orderNumber: order.orderNumber,
          address:
            order.deliveryCity !== '' &&
            order.deliveryCity !== null &&
            order.deliveryState !== '' &&
            order.deliveryState !== null
              ? order.deliveryCity + ', ' + order.deliveryState
              : order.deliveryCity !== ''
              ? order.deliveryCity
              : order.deliveryState !== ''
              ? order.deliveryState
              : '',
          city: order.deliveryCity,
          state: order.deliveryState,
          // canOrderProceed: order.deliveryDate > earliestDeliveryDate,
          // earliestDeliveryDate: earliestDeliveryDate,
          canOrderProceed: order.canOrderProceed,
          earliestDeliveryDate: order.earliestDeliveryDate,
          deliveryDate:
            order.deliveryDate !== '' && order.deliveryDate !== null ? order.deliveryDate : '',
          deliveryTime:
            order.deliveryTime !== '' && order.deliveryTime !== null
              ? FormatTimeForDisplay(order.deliveryTime)
              : '',
          subtotal: order.subtotal ? +order.subtotal.toString().replace(/[","]/g, '') : '0.00',
          orderItemsSubtotal: order.orderItemsSubtotal
            ? +order.orderItemsSubtotal.toString().replace(/[","]/g, '')
            : '0.00',
          additionalCharges: order.additionalCharges
            ? +order.additionalCharges.toString().replace(/[","]/g, '')
            : '0.00',
          status: order.status,
          restaurantStatus: order.restaurantStatus,
          orderStatus:
            userRole === 'Vendor' &&
            order.status === 'Placed' &&
            order.restaurantStatus === 'Accepted'
              ? 'New'
              : userRole === 'Vendor' &&
                order.status === 'Placed' &&
                order.restaurantStatus === 'Modified'
              ? 'New'
              : order.status === 'Placed' && order.restaurantStatus === 'Rejected'
              ? 'Rejected'
              : order.status === 'Rejected' && order.restaurantStatus === 'Deleted'
              ? 'Rejected'
              : order.status === 'Canceled' || order.restaurantStatus === 'Canceled'
              ? 'Canceled'
              : userRole === 'User'
              ? order.status
              : userRole === 'Vendor'
              ? order.restaurantStatus
              : order.status,
          displayStatus:
            userRole === 'Admin' && order.status !== order.restaurantStatus
              ? order.status + ' / ' + order.restaurantStatus
              : // : order.status === 'Placed' && order.restaurantStatus === 'Accepted'
              // ? 'Payment Required'
              order.status === 'Placed' &&
                order.restaurantStatus === 'Accepted' &&
                order.canOrderProceed
              ? 'Payment Required'
              : order.status === 'Placed' && order.restaurantStatus === 'Rejected'
              ? 'Rejected'
              : order.status === 'Canceled' || order.restaurantStatus === 'Canceled'
              ? 'Canceled'
              : userRole === 'User' &&
                order.canOrderProceed &&
                order.restaurantStatus === 'Modified'
              ? 'Placed / Modified'
              : // : userRole === 'User'
              // ? order.status
              userRole === 'User' && order.canOrderProceed
              ? order.status
              : userRole === 'User' && !order.canOrderProceed
              ? orderLeadTimeExceededStatusValue
              : userRole === 'Vendor'
              ? order.restaurantStatus
              : // : userRole === 'Admin'
                // ? order.status + '/' + order.restaurantStatus
                order.status,
          restaurantName: order.restaurantName,
          teamName: order.teamName,
          subtotalFormatted: order.subtotalFormatted,
          calculatedTotalFormatted: order.calculatedTotalFormatted,
          restaurantsCutFormatted: order.restaurantsCutFormatted,
        };
      });

      // console.log('transformedRecords');
      // console.log(transformedRecords);

      setRecords(transformedRecords);

      const filteredRecords = transformedRecords.filter(
        (record) => record.orderStatus === defaultFilterByStatus || defaultFilterByStatus === 'All'
      );

      setDisplayedRecords(filteredRecords);

      // console.log(filteredRecords);
    } else {
      console.log(' ***** ERROR ** getAllOrderRecords ** ERROR ***** ');

      // If a 401 is returned then there is an issue with the session (most likely).
      if (responseData.statusCode === 401) {
        history.push('sessionexpired');
      }
    }

    setIsLoading(false);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    defaultFilterByStatus,
    restaurantUuid,
    userRole,
    history,
    refreshApp,
    FormatTimeForDisplay,
  ]);

  const filterRecordsForDisplay = useCallback(
    (status) => {
      // console.log('### filterRecordsForDisplay ###');
      // console.log('status', status);

      let filteredRecords = [];

      if (status === 'Deleted') {
        filteredRecords = records.filter(
          (record) => record.status === status || record.restaurantStatus === status
        );
      } else if (status === 'Placed') {
        filteredRecords = records.filter(
          (record) =>
            record.orderStatus === status ||
            record.orderStatus === paymentNotConfirmedStatusValue ||
            status === 'All'
        );
      } else {
        filteredRecords = records.filter(
          (record) => record.orderStatus === status || status === 'All'
        );
      }

      setDisplayedRecords(filteredRecords);
    },
    [records]
  );

  const filterRecordsHandler = (status) => {
    // console.log('### filterRecordsHandler ###');
    // console.log('status', status);
    // console.log('ordersStatusFilter', ordersStatusFilter);

    filterRecordsForDisplay(status);
    setFilterByStatus(status);

    authCtx.setOrdersFilter(status);
  };

  const searchHandler = (event) => {
    let searchValue = searchRef.current.value;
    setFilterFunction({
      function: (records) => {
        if (searchValue === '') {
          return records;
        } else {
          searchValue = searchValue.toLowerCase();
          let filteredRecords = records.filter(
            (record) =>
              record.deliveryDate.toLowerCase().includes(searchValue) ||
              record.teamName.toLowerCase().includes(searchValue) ||
              record.restaurantName.toLowerCase().includes(searchValue) ||
              record.address.toLowerCase().includes(searchValue) ||
              record.orderNumber.toLowerCase().includes(searchValue) ||
              record.orderNumber.toLowerCase().includes(searchValue)
          );
          return filteredRecords;
        }
      },
    });
  };

  const clearSearchHandler = () => {
    searchRef.current.value = '';
    searchHandler();
  };

  const handleDisplayOrder = (orderUuid) => {
    history.replace(dashboardUrl + 'orders/' + orderUuid);
  };

  const handlePrintOrderDetails = (orderUuid, orderNumber) => {
    setSelectedOrderUuid(orderUuid);
    setSelectedOrderNumber(orderNumber);
    setOpenPopupPrintOrderDetails(true);
  };

  const handleDelete = (orderUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteData(orderUuid);
    setNotify({ isOpen: true, message: 'Order Successfully Deleted', type: 'error' });
  };

  const deleteData = async (orderUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);

      getAllOrderRecords();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (authCtx.ordersStatusFilter === '') {
      authCtx.setOrdersFilter(defaultFilterByStatus);
    }
  }, [authCtx, defaultFilterByStatus]);

  // Commenting this out for now.
  // TODO:  Cleanup this code at some point.
  // useEffect(() => {
  //   authCtx.resetLogoutTimer();
  // }, [authCtx]);

  useEffect(() => {
    getAllOrderRecords();
  }, [getAllOrderRecords]);

  useEffect(() => {
    filterRecordsForDisplay(filterByStatus);
  }, [filterRecordsForDisplay, filterByStatus]);

  const headerCells = [
    { id: 'deliveryDate', label: 'Delivery Date' },
    { id: 'deliveryTime', label: 'Delivery Time' },
    { id: 'orderNumber', label: 'Order Number' },
    { id: 'teamName', label: 'Team' },
    { id: 'restaurantName', label: 'Restaurant' },
    { id: 'address', label: 'City' },
    {
      id: 'subtotal',
      label:
        userRole === 'Vendor' && (filterByStatus === 'Scheduled' || filterByStatus === 'Completed')
          ? 'Payout'
          : 'Total',
      align: 'right',
    },
    { id: 'status', label: userRole === 'Admin' ? 'Status Team/Vendor' : 'Status' },
    { id: 'actions', label: 'Actions', disableSorting: true, align: 'center' },
  ];

  if (userRole === 'User') {
    const objWithIdIndex = headerCells.findIndex((obj) => obj.id === 'teamName');
    headerCells.splice(objWithIdIndex, 1);
  }

  if (userRole === 'Vendor') {
    const objWithIdIndex = headerCells.findIndex((obj) => obj.id === 'restaurantName');
    headerCells.splice(objWithIdIndex, 1);
  }

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    displayedRecords,
    headerCells,
    filterFunction
  );

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box elevation={4} sx={{ maxWidth: '120rem', p: '2rem' }}>
        <OrdersListMenu
          filterByStatus={filterByStatus}
          onFilterRecords={filterRecordsHandler}
          onSearch={searchHandler}
          onClearSearch={clearSearchHandler}
          searchRef={searchRef}
          userRole={userRole}
        />

        <Box>
          <TblContainer>
            <TblHeader />

            {!isLoading && recordsAfterPagingAndSorting().length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography variant='h6' color='error' align='center' sx={{ pt: '2rem' }}>
                      No {filterByStatus} Orders Found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}

            {recordsAfterPagingAndSorting().length > 0 ? (
              <TableBody sx={{ backgroundColor: '#ffffff' }}>
                {recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>{record.deliveryDate}</TableCell>
                    <TableCell>{record.deliveryTime}</TableCell>
                    <TableCell>{record.orderNumber}</TableCell>
                    {userRole !== 'User' ? <TableCell>{record.teamName}</TableCell> : null}
                    {userRole !== 'Vendor' ? <TableCell>{record.restaurantName}</TableCell> : null}
                    <TableCell>{record.address}</TableCell>
                    <TableCell align='right'>
                      {userRole === 'Vendor'
                        ? filterByStatus === 'Scheduled' || filterByStatus === 'Completed'
                          ? record.restaurantsCutFormatted
                          : record.calculatedTotalFormatted
                        : record.calculatedTotalFormatted}
                    </TableCell>
                    <TableCell>{record.displayStatus}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <FormControls.ActionButtonControl
                        color='primary'
                        toolTip='Display Order'
                        onClick={() => {
                          handleDisplayOrder(record.orderUuid);
                        }}
                      >
                        <VisibilityIcon sx={{ fontSize: '1.5rem' }} />
                      </FormControls.ActionButtonControl>

                      <FormControls.ActionButtonControl
                        color='warning'
                        toolTip='Print Order'
                        onClick={() => {
                          handlePrintOrderDetails(record.orderUuid, record.orderNumber);
                        }}
                      >
                        <PrintIcon sx={{ fontSize: '1.5rem' }} />
                      </FormControls.ActionButtonControl>

                      {(record.status === 'Draft' && userRole !== 'Vendor') ||
                      (record.status === 'Completed' && userRole !== 'Vendor') ||
                      (record.restaurantStatus === 'Completed' && userRole !== 'User') ||
                      record.status === 'Canceled' ||
                      record.restaurantStatus === 'Rejected' ||
                      record.status === 'Rejected' ? (
                        <FormControls.ActionButtonControl
                          color='error'
                          toolTip='Delete Order'
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: 'Are you sure you want to delete this Order.',
                              subTitle: 'It will be permanently deleted.',
                              onConfirm: () => {
                                handleDelete(record.orderUuid);
                              },
                            });
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: '1.5rem' }} />
                        </FormControls.ActionButtonControl>
                      ) : null}

                      {record.canOrderProceed &&
                      record.status === 'Placed' &&
                      record.restaurantStatus === 'Accepted' &&
                      userRole === 'User' ? (
                        <FormControls.ActionButtonControl
                          color='success'
                          toolTip='Pay for Order'
                          onClick={() => {
                            handleDisplayOrder(record.orderUuid);
                          }}
                          // onClick={() => {
                          //   setConfirmDialog({
                          //     isOpen: true,
                          //     title: 'Indicate Payment for this Order?',
                          //     subTitle:
                          //       'The Vendor will confirm Payment before they proceed with the Order.',
                          //     onConfirm: () => {
                          //       indicatePaymentHandler(record.orderUuid);
                          //     },
                          //   });
                          // }}
                        >
                          <PaidIcon sx={{ fontSize: '1.5rem' }} />
                        </FormControls.ActionButtonControl>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </TblContainer>
        </Box>

        <div className='pagination'>
          <TblPagination />
        </div>

        <Box className='pt-20'>
          <Typography align='right' className='small-text'>
            {userRole === 'Vendor' &&
            (filterByStatus === 'Scheduled' || filterByStatus === 'Completed')
              ? ''
              : 'The Total displayed includes all known charges at the time. Sales Tax will be caculated at payment.'}
          </Typography>
        </Box>
      </Box>

      <Popup
        openPopup={openPopupPrintOrderDetails}
        setOpenPopup={setOpenPopupPrintOrderDetails}
        maxWidth='md'
        title={'Printout of Order: ' + selectedOrderNumber}
      >
        <OrderDetailsPrintout orderUuid={selectedOrderUuid} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default OrdersList;
