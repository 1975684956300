import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AuthContext from '../../store/auth-context';
import classes from '../CSS/styled-table.module.css';

const RestaurantListLocationsTable = (props) => {
  const { restaurants } = props;
  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();

  const restaurantSelectedHandler = (event) => {
    // console.log('** restaurantSelectedHandler ** ');
    const action = event.target.getAttribute('data-action');
    const uuid = event.target.getAttribute('data-restaurant-uuid');
    const name = event.target.getAttribute('data-restaurant-name');
    const city = event.target.getAttribute('data-restaurant-city');
    const state = event.target.getAttribute('data-restaurant-state');

    props.updateSelectedRestaurant(uuid, name, city, state);

    switch (action) {
      case 'details':
        if (userRole === 'Vendor') {
          history.replace(`${dashboardUrl}vendor-profile`);
        } else {
          history.replace(`${dashboardUrl}restaurants/details/${uuid}`);
        }
        break;
      // Making this the default.
      // case 'menu':
      //   if (userRole === 'Vendor') {
      //     history.replace(`${dashboardUrl}menu-items`);
      //   } else {
      //     history.replace(`${dashboardUrl}restaurants/menu-items/${uuid}`);
      //   }
      //   break;
      case 'edit':
        history.replace(`${dashboardUrl}restaurants/edit/${uuid}`);
        break;
      case 'orders':
        history.replace(`${dashboardUrl}orders`);
        break;
      default:
        if (userRole === 'Vendor') {
          history.replace(`${dashboardUrl}menu-items`);
        } else {
          history.replace(`${dashboardUrl}restaurants/menu-items/${uuid}`);
        }
    }

    // if (action === 'edit') {
    //   history.replace(`${dashboardUrl}restaurants/edit/${uuid}`);
    // } else if (action === 'menu') {
    //   if (userRole === 'Vendor') {
    //     history.replace(`${dashboardUrl}menu-items`);
    //   } else {
    //     history.replace(`${dashboardUrl}restaurants/menu-items/${uuid}`);
    //   }
    // } else {
    //   if (userRole === 'Vendor') {
    //     history.replace(`${dashboardUrl}vendor-profile`);
    //   } else {
    //     history.replace(`${dashboardUrl}restaurants/details/${uuid}`);
    //   }
    // }
  };

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Type of Food</th>
        {userRole !== 'User' ? <th>Status</th> : null}
        {userRole !== 'User' ? <th>Payment Account Status</th> : null}
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return restaurants.map((restaurant, index) => (
      <tr key={index}>
        <td>
          {userRole !== 'User' ? (
            <>
              {restaurant.isParent ? '* ' : ''}
              {restaurant.name}
            </>
          ) : (
            <>{restaurant.name}</>
          )}
        </td>
        <td>{restaurant.address}</td>
        <td>{restaurant.city}</td>
        <td>{restaurant.state}</td>
        <td>{restaurant.foodType}</td>
        {userRole !== 'User' ? <td>{restaurant.status}</td> : null}
        {userRole !== 'User' ? <td>{restaurant.paymentAccountStatus}</td> : null}
        <td>
          <div className={classes['table-actions-container']}>
            <button
              data-action={'details'}
              data-restaurant-uuid={restaurant.restaurantUuid}
              data-restaurant-name={restaurant.name}
              data-restaurant-city={restaurant.city}
              data-restaurant-state={restaurant.state}
              onClick={restaurantSelectedHandler}
              className='small'
            >
              Details
            </button>
            {userRole === 'Admin' && (
              <button
                data-action={'edit'}
                data-restaurant-uuid={restaurant.restaurantUuid}
                data-restaurant-name={restaurant.name}
                data-restaurant-city={restaurant.city}
                data-restaurant-state={restaurant.state}
                onClick={restaurantSelectedHandler}
                className='small'
              >
                Edit
              </button>
            )}
            <button
              data-action={'menu'}
              data-restaurant-uuid={restaurant.restaurantUuid}
              data-restaurant-name={restaurant.name}
              data-restaurant-city={restaurant.city}
              data-restaurant-state={restaurant.state}
              onClick={restaurantSelectedHandler}
              className='small'
            >
              Menu
            </button>
            {userRole === 'Vendor' && (
              <button
                data-action={'orders'}
                data-restaurant-uuid={restaurant.restaurantUuid}
                data-restaurant-name={restaurant.name}
                data-restaurant-city={restaurant.city}
                data-restaurant-state={restaurant.state}
                onClick={restaurantSelectedHandler}
                className='small'
              >
                Orders
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <div className='table-holder'>
        <table className={`${classes['styled-table']} ${classes['full-width']}`}>
          <thead>{displayTableHeader()}</thead>
          <tbody>{displayTableBody()}</tbody>
        </table>
      </div>

      {userRole !== 'User' ? <div className='small-text'>* Parent (Main) Restaurant</div> : null}
    </div>
  );
};

export default RestaurantListLocationsTable;
